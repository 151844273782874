import { put, call, takeEvery, all } from 'redux-saga/effects';
import { throwErrorActions } from 'store/slices/throwError';
import { saveToStorage } from 'utils/persist';
import cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { getAuthTokens } from 'services/login';

import { Types } from './types';

export function* login(props) {
  try {
    const {
      data: { access_token, refresh_token },
    } = yield call(getAuthTokens, props.params);

    const { role } = jwt_decode(access_token);

    if (access_token && role === 'admin') {
      cookies.set('authToken', access_token);
      saveToStorage('authRefreshToken', refresh_token);
      props.history.push('/dashboard');
    } else {
      yield put(
        throwErrorActions.updateMessage({
          message: 'ERR_WRONG_EMAIL_OR_PASSWORD',
        }),
      );
    }

    setTimeout(() => {
      props.setLoadLogin(false);
    }, 800);
  } catch (err) {
    yield put(
      throwErrorActions.updateMessage({
        message: err.response.data.hasOwnProperty('message') ? err.response.data.message : 'Error',
      }),
    );
    setTimeout(() => {
      props.setLoadLogin(false);
    }, 800);
  }
}

export default function* loginSaga() {
  yield all([takeEvery(Types.LOGIN, login)]);
}
