import { call, put, takeEvery, all } from 'redux-saga/effects';
import { Types } from './types/index';
import { blacklistApiService } from 'services';
import { blackListActions } from 'store/slices/blackListReducer';
import { throwErrorActions } from 'store/slices/throwError';

export function* createBlackListItem(props) {
  try {
    const result = yield call(() => blacklistApiService.createBlackList(props.payload));

    yield put(
      blackListActions.updateBlackList({
        ...props.payload,
        _id: result.data._id,
      }),
    );
  } catch (err) {
    yield put(
      throwErrorActions.updateMessage({
        message: err.response.data.message,
      }),
    );
  }
}

export function* editBlackListItem(props) {
  try {
    yield call(() =>
      blacklistApiService.editBlackList(
        props.valueNotChanged
          ? {
              content_type: props.payload.content_type,
              description: props.payload.description,
              word_type: props.payload.word_type,
            }
          : {
              content_type: props.payload.content_type,
              description: props.payload.description,
              value: props.payload.value,
              word_type: props.payload.word_type,
            },
        props.payload['_id'],
      ),
    );

    yield put(
      blackListActions.editBlackList({
        newList: { ...props.payload },
        index: props.index,
      }),
    );
  } catch (err) {
    yield put(
      throwErrorActions.updateMessage({
        message: err.response.data.message,
      }),
    );
  }
}

export function* updateList(props) {
  try {
    let result = yield call(() =>
      blacklistApiService.getList({
        limit: props.query.limit || 10,
        offset: props.query.offset,
        content_type: props.query.contentType,
      }),
    );
    yield put(blackListActions.getBlackListedWords(result.data));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* searchBlackList(props) {
  try {
    let result = yield call(() =>
      blacklistApiService.search(
        {
          search: props.searchValue,
        },
        {
          limit: 10,
          offset: props.query.offset,
          content_type: props.query.contentType,
        },
      ),
    );

    yield put(blackListActions.getBlackListedWords({ ...result.data, isSearch: true }));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export default function* blackListSaga() {
  yield all([
    takeEvery(Types.GET_BLACKLIST, updateList),
    takeEvery(Types.CREATE_BLACK_LIST_ITEM, createBlackListItem),
    takeEvery(Types.EDIT_BLACK_LIST_ITEM, editBlackListItem),
    takeEvery(Types.SEARCH_BLACK_LIST, searchBlackList),
  ]);
}
