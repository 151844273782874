import cookies from 'js-cookie';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { getOriginalImageUrl } from 'utils/getImageUrl';
import { useInjectReducer } from 'utils/redux-injectors';
import { handleProfileAd } from 'utils/getApproveData';
import { mediaService, accountDetailsService } from 'services';
import { asyncAction } from 'store/utils/asyncActions';
import { adsAdvertismentsData, accountProfileDetailsSelector } from 'store/reselect';

export const initialState = {
  account: null,
  logConfigs: null,
  logs: [],
  logsCount: 0,
  profiles: {},
  profileImges: {},
  ad: null,
  expenses: null,
  adImges: {},
  adImgesPaths: {},
  adProfiles: {},
  categories: null,
  labels: null,
  adPhotos: null,
  verificationPhotos: null,
  ads: null,
  profileConfigs: null,
  verificationConfigs: null,
  verifications: {},
  pinedImage: null,
  accountConfig: {},
};

export const getVerificationData = asyncAction(
  'accountDetails/getVerifications',
  async ({ ids, id }) => {
    const {
      data: { result },
    } = await accountDetailsService.getVerificationsBulk(ids);

    return { id, result };
  },
);

export const getPinedImage = asyncAction(
  'accountDetails/getPinedImage',
  async (_payload, _dispatch, getState) => {
    const token = cookies.get('authToken');
    const { id } = accountProfileDetailsSelector(getState());

    const { data } = await accountDetailsService.getPinedPhoto(id);

    if (data.result) {
      const images = await mediaService.getBulkImages({
        ids: [data.image_id],
      });

      return getOriginalImageUrl({ ...images.data[0], token });
    } else {
      const { adImages, ad: { dataForUpdate } = {} } = adsAdvertismentsData(getState());

      if (dataForUpdate) {
        const [firstImage] = adImages?.[dataForUpdate?._id]
          ? [adImages?.[dataForUpdate?._id]]
          : Object.values(adImages);
        if (firstImage[0]) {
          const existedImage = await mediaService.getBulkImages({
            ids: [firstImage[0].image_id],
          });

          return getOriginalImageUrl({ ...existedImage.data[0], token });
        }
      }
    }
  },
);

export const getAccountConfig = asyncAction('accountDetails/getAccountConfig', async () => {
  const { data } = await accountDetailsService.getAccountConfigs();

  return data;
});

export const accountDetailsSlice = createSlice({
  name: 'accountDetails',
  initialState,
  extraReducers: builder => {
    builder.addCase(getAccountConfig.fulfilled, (state, action) => {
      state.accountConfig = action.payload;
    });
    builder.addCase(getVerificationData.fulfilled, (state, action) => {
      state.verifications[action.payload.id] = action.payload.result;
    });
    builder.addCase(getPinedImage.fulfilled, (state, action) => {
      state.pinedImage = action.payload;
    });
  },
  reducers: {
    accountData: (state, action) => {
      state.account = action.payload.data;
    },
    checkContactVerification: (state, action) => {
      let cloneAccount = [{ ...state.account[0] }];
      if (typeof action.payload.value === 'string' && state.account) {
        cloneAccount[0].emails[action.payload.index].is_verified = action.payload.data.is_verified;
      } else {
        cloneAccount[0].phone_numbers[action.payload.index].is_verified =
          action.payload.data.is_verified;
      }
      state.account = cloneAccount;
    },
    resetData: state => {
      state.account = null;
    },

    //profiles

    saveProfile: (state, action) => {
      state.profiles[action.payload.accountId] = action.payload.data;
    },

    saveProfileImages: (state, action) => {
      state.profileImges[action.payload.id] = action.payload.data;
    },

    changeStatus: (state, action) => {
      state.profileImges[action.payload.id].status = action.payload.action;
    },

    deleteProfileVerificationDoc: (state, action) => {
      state.profileImges[action.payload.id].documents.splice(action.payload.index, 1);
      state.profileImges[action.payload.id].imagesPath.splice(action.payload.index, 1);
    },

    //logs

    saveLogConfigs: (state, action) => {
      state.logConfigs = action.payload;
    },
    saveLogs: (state, action) => {
      state.logsCount = action.payload.count;
      state.logs = action.payload.result;
    },

    //ads

    saveAd: (state, action) => {
      state.account = action.payload.account;
      state.expenses = action.payload.expenses;
      state.ad = handleProfileAd(action.payload.ad);

      if (Object.keys(action.payload.adsPhotos).length) {
        const newAdsImg = {
          ...state.adImges,
          ...action.payload.adsPhotos,
        };
        state.adImges = newAdsImg;
      }
    },

    saveProfileInfo: (state, action) => {
      if (action.payload.hasOwnProperty('verifImges')) {
        state.adProfiles = action.payload.profile;
        state.verificationPhotos = action.payload.verifImges;
        state.ads = action.payload.ads;
      } else {
        state.adProfiles = action.payload;
      }
    },

    updateProfileAd: (state, action) => {
      state.ad.dataForUpdate = {
        ...state.ad.dataForUpdate,
        ...action.payload,
      };
    },

    saveCategories: (state, action) => {
      state.categories = action.payload;
    },

    saveLabels: (state, action) => {
      state.labels = action.payload;
    },

    saveAdImages: (state, action) => {
      state.adPhotos = action.payload;
    },

    saveAdImagesPaths: (state, action) => {
      state.adImgesPaths = action.payload;
    },

    updateShowName: (state, action) => {
      state.ad.showName = action.payload;
    },

    resetAd: state => {
      state.ad = null;
      state.labels = null;
    },

    saveProfileCon: (state, action) => {
      state.profileConfigs = action.payload;
    },
    saveVerificationConfig: (state, action) => {
      state.verificationConfigs = action.payload;
    },

    updateProfile: (state, action) => {
      state.adProfiles = { ...state.adProfiles, ...action.payload };
    },
  },
});

export const { actions: accountDetailsActions, reducer } = accountDetailsSlice;

export const useBlackListedWordsSlice = () => {
  useInjectReducer({ key: accountDetailsSlice.name, reducer });
  return { actions: accountDetailsActions };
};
