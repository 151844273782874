let config = {
  BASE_HOST: process.env.REACT_APP_BASE_HOST,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,
  IMAGE_BASE_URL: process.env.REACT_APP_IMAGE_BASE_URL,
  FRAUD_PREVENTION_URL: process.env.REACT_APP_FRAUD_PREVENTION_URL,
  FRAUD_PREVENTION_TOKEN: process.env.REACT_APP_FRAUD_PREVENTION_TOKEN,
  // API_BASE_URL: 'https://api.sexjobs.nl/v1' || process.env.REACT_APP_API_BASE_URL,
  // IMAGE_BASE_URL: 'https://i.sexjobs.nl' || process.env.REACT_APP_IMAGE_BASE_URL,
};

if (process.env.NODE_ENV === 'production') {
  config = {
    BASE_HOST: window.BASE_HOST,
    API_BASE_URL: window.API_BASE_URL,
    IMAGE_BASE_URL: window.IMAGE_BASE_URL,
    WEB_SOCKET_URL: window.WEB_SOCKET_URL,
    FRAUD_PREVENTION_URL: window.FRAUD_PREVENTION_URL,
    FRAUD_PREVENTION_TOKEN: window.FRAUD_PREVENTION_TOKEN,
  };
}

export default config;
