import { createSlice } from 'utils/@reduxjs/toolkit';
import cookies from 'js-cookie';
import { removeFromStorage } from 'utils/persist';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState = {
  message: '',
  success: '',
};

export const throwErrorSlice = createSlice({
  name: 'throwError',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      if (action.payload.message === 'Request failed with status code 401') {
        cookies.remove('token');
        removeFromStorage('refreshToken');
      }
      state.message = action.payload.message;
    },
    updateSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const { actions: throwErrorActions, reducer } = throwErrorSlice;

export const useThrowErrorSlice = () => {
  useInjectReducer({ key: throwErrorSlice.name, reducer });
  return { actions: throwErrorActions };
};
