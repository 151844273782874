import axios from 'axios';
import config from 'config';

const getFraudField = (data, key = 'field') => data.map(field => field[key]);

export const fraudPreventionService = {
  getByFilter: async filters => {
    const formData = new FormData();

    Object.entries(filters).forEach(([key, value]) => formData.append(key, value));

    return await axios.post(`${config.FRAUD_PREVENTION_URL}/listing`, formData, {
      headers: {
        Authorization: `Bearer ${config.FRAUD_PREVENTION_TOKEN}`,
        'x-version': 'v1',
      },
    });
  },
  createFraud: async ({ email, iban, phone, images, phoneCodes }) => {
    const formData = new FormData();

    const fraudData = {
      ibans: getFraudField(iban),
      emails: getFraudField(email),
      images: getFraudField(images, 'file'),
      phone_numbers: phone.map(({ id, field }) => ({
        phone_number: field,
        country_code: phoneCodes[id],
      })),
    };

    Object.entries(fraudData).forEach(([key, value]) =>
      value.forEach((field, index) => {
        if (key === 'phone_numbers') {
          formData.append(`${key}[${index}][country_code]`, field.country_code);
          formData.append(`${key}[${index}][phone_number]`, field.phone_number);
        } else if (key === 'images') {
          formData.append(key, field);
        } else {
          formData.append(`${key}[${index}]`, field);
        }
      }),
    );

    formData.append('site', 'Manually');

    return await axios.post(`${config.FRAUD_PREVENTION_URL}/fraud`, formData, {
      headers: {
        Authorization: `Bearer ${config.FRAUD_PREVENTION_TOKEN}`,
        'x-version': 'v1',
      },
    });
  },
  deleteByKey: async ({ id, key }) =>
    await axios.delete(`${config.FRAUD_PREVENTION_URL}/fraud-${key}/${id}`, {
      data: { id },
      headers: {
        Authorization: `Bearer ${config.FRAUD_PREVENTION_TOKEN}`,
        'x-version': 'v1',
      },
    }),
};
