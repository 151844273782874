import { HttpRequest } from 'services';

export const onlineTickets = {
  getList: (type, status, offset, limit) => {
    const query = offset || limit ? `&offset=${offset}&limit=${limit}` : '';
    return HttpRequest.get(`/tickets?type=${type}&status=${status}${query}`);
  },
  getAccountOrProfiles: (ids, typeUrl) => {
    return HttpRequest.post(`${typeUrl}/bulk`, {
      ids: ids,
    });
  },
  getProfileAds: ids => {
    return HttpRequest.post('profile-ad/bulk', {
      ids: ids,
    });
  },
};
