import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { asyncAction } from 'store/utils/asyncActions';
import { accountDetailsService } from 'services';

export const initialState = {
  isOpenAsideMenu: false,
  words: [],
  quantity: 0,
  approvedTickets: {},
  policeClaimUrl: '',
  profileAdConfigs: {},
};

export const getProfileAdConfigs = asyncAction('base/getProfileAdConfigs', async () => {
  const { data } = await accountDetailsService.getProfileAdConfigs();

  return data;
});

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    toggleAsideMenu: (state, action) => {
      state.isOpenAsideMenu = action.payload;
    },
    setApprovedTicket(state, action) {
      state.approvedTickets[action.payload.ad_id] = action.payload;
      state.approvedTickets[action.payload.ticket_id] = action.payload;
    },
    setPoliceClaimUrl(state, action) {
      state.policeClaimUrl = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfileAdConfigs.fulfilled, (state, action) => {
      state.profileAdConfigs = action.payload;
    });
  },
});

export const { actions: baseActions, reducer } = baseSlice;

export const useBaseSlice = () => {
  useInjectReducer({ key: baseSlice.name, reducer });
  return { actions: baseActions };
};
