import styled from 'styled-components';

export const Main = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
  background: linear-gradient(180deg,#0558a8 0%,#003f8f 100%);
`;

export const InputBlocks = styled.div`
	max-width: 100%;
	width: 450px;
`;

export const RedFont = styled.div`
	color: red;
	font-size: 10px;
	margin-bottom: 20px;
	margin-top: 3px;
	text-align: center;
`;

export const LogoBlock = styled.div`
	text-align: center;
  width: 200px;
  margin: 0 auto 20px;
`;

export const RowButton = styled.div`
	text-align: -webkit-center;
	margin: 3rem 0;
`;



export const SigButton = styled.button`
	width: 140px;
	height: 40px;
	display: flex;
	padding: 0 !important;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: 60px;
	background-color: #36a3f7;
	border: none;
	cursor: pointer;
`;
