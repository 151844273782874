import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/Shared/LoadingIndicator';

export const FraudPrevention = lazyLoad(
  () => import('.'),
  module => module.FraudPrevention,
  {
    fallback: <LoadingIndicator />,
  },
);
