import { put, all, takeEvery } from 'redux-saga/effects';
import { Types } from './types/index';
import { throwErrorActions } from 'store/slices/throwError';

export function* throwError(props) {
  console.log('props: ', props);
  yield put(throwErrorActions.updateMessage({ message: props.message }));
}

export default function* throwErrorSaga() {
  yield all([takeEvery(Types.THROW_ERROR, throwError)]);
}
