import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState = {
	lists: null,
	count: 0,
	chatList: null,
	theards: null,
	hasBefore: true,
	hasAfter: true,
};

export const messagesSlice = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		saveMessages: (state, action) => {
			state.lists = [...action.payload.data];
			state.count = action.payload.count;
		},
		saveReportChat: (state, action) => {
			state.chatList =
				action.payload.page === 1
					? [...action.payload.reportChat]
					: [...action.payload.reportChat, ...state.chatList];
			if (action.payload.page === 1) {
				state.theards = action.payload.threadRes.result;
			}
		},
		savePartitionChat: (state, action) => {
			if (action.payload.partition === 'before') {
				state.chatList = [
					[
						...action.payload.reportChat.reverse(),
						...state.chatList[0],
					],
					state.chatList[1],
				];
				state.hasBefore =
					action.payload.reportChat.length === 5 ? true : false;
			} else {
				state.chatList = [
					state.chatList[0],
					[...state.chatList[1], ...action.payload.reportChat],
				];
				state.hasAfter =
					action.payload.reportChat.length === 5 ? true : false;
			}
		},
		resetChat: state => {
			state.chatList = null;
			state.theards = null;
			state.hasBefore = true;
			state.hasAfter = true;
		},
		changeViewd: (state, action) => {
			const newState = [...state.lists];
			newState[action.payload] = {
				...newState[action.payload],
				_meta: { ...newState[action.payload]._meta, is_viewed: true },
			};
			state.lists = newState;
		},
	},
});

export const { actions: messagesActions, reducer } = messagesSlice;

export const useMessagesSlice = () => {
	useInjectReducer({ key: messagesSlice.name, reducer });
	return { actions: messagesActions };
};
