import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState = {
	lists: [],
	count: 0,
	draft: {},
	filterList: [],
};

export const categorySettingsSlice = createSlice({
	name: 'categorySettings',
	initialState,
	reducers: {
		saveCategories: (state, action) => {
			state.lists = action.payload;
		},

		saveCategoriesParams: (
			state,
			action,
		) => {
			state.draft = {
				...state.draft,
				[action.payload.id]: action.payload.data,
			};
		},

		filterCategories: (state, action) => {
			state.filterList = state.lists.filter(el => {
				const re = new RegExp(action.payload, 'i');
				return re.test(el.name);
			});
		},

		updateList: (state, action) => {
			const index = state.lists.findIndex(
				el => el.id === action.payload.id,
			);
			state.lists[index] = {
				...state.lists[index],
				...action.payload.category,
			};
			state.draft[action.payload.id].prices = action.payload.price;
		},
	},
});

export const {
	actions: categorySettingsActions,
	reducer,
} = categorySettingsSlice;

export const useCategorySettingsSlice = () => {
	useInjectReducer({ key: categorySettingsSlice.name, reducer });
	return { actions: categorySettingsActions };
};
