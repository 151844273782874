import { HttpRequest } from 'services';

const apiUrl = '/account/watchlists';

export const watchListService = {
  getList: params => {
    return HttpRequest.get(apiUrl, { params });
  },

  addToList: params => {
    return HttpRequest.post(apiUrl, params);
  },

  deleteWatchList: id => {
    return HttpRequest.delete(apiUrl + '/' + id);
  },

  editWatchList: ({ id, params }) => {
    return HttpRequest.put(apiUrl + '/' + id, { ...params });
  },
};
