import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

import { blacklistApiService } from 'services';
import { asyncAction } from 'store/utils/asyncActions';

export const initialState = {
  words: [],
  quantity: 0,
  blacklistMatch: {},
};

export const getBlacklistMatch = asyncAction('blackList/getBlacklistMatch', async () => {
  const {
    data: { result },
  } = await blacklistApiService.getBlacklistMatch();

  return result;
});

export const blackListedWordsSlice = createSlice({
  name: 'blackList',
  initialState,
  reducers: {
    getBlackListedWords: (state, action) => {
      state.words = action.payload.blacklists;

      state.quantity = action.payload.quantity;
    },
    updateBlackList: (state, action) => {
      if (state.words.length < 10) {
        state.quantity = state.quantity + 1;
        state.words = [...state.words, action.payload];
      } else {
        state.quantity = state.quantity + 1;
      }
    },
    editBlackList: (state, action) => {
      let cloneWords = [...state.words];
      cloneWords[action.payload.index] = action.payload.newList;
      state.words = cloneWords;
    },
  },
  extraReducers: builder => {
    builder.addCase(getBlacklistMatch.fulfilled, (state, action) => {
      state.blacklistMatch = action.payload;
    });
  },
});

export const { actions: blackListActions, reducer } = blackListedWordsSlice;

export const useBlackListedWordsSlice = () => {
  useInjectReducer({ key: blackListedWordsSlice.name, reducer });
  return { actions: blackListActions };
};
