import { HttpRequest } from 'services';

const apiUrl = '/inventory';

export const categorySettingsService = {
  getCategories: () => {
    return HttpRequest.get(`${apiUrl}/categories`);
  },
  getPrices: (id) => {
    return HttpRequest.get(`${apiUrl}/categories/${id}/prices`);
  },
  getCategoryLabels: (id) => {
    return HttpRequest.get(`${apiUrl}/categories/${id}/sections`);
  },
  getCategoryFilteredLabels: data => {
    return HttpRequest.post(`${apiUrl}/categories/sections/filter`, data);
  },
};
