/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { reducer as baseReducer } from './slices/baseReducer';
import { reducer as blackListReducer } from './slices/blackListReducer';
import { reducer as accountDetailsReducer } from './slices/accountDetails';
import { reducer as throwErrorReducer } from './slices/throwError';
import { reducer as dictionaryReducer } from './slices/dictionary';
import { reducer as messagesReducer } from './slices/messages';
import { reducer as watchListReducer } from './slices/watchList';
import { reducer as categorySettingsReducer } from './slices/categorySettings';
import { reducer as onlineTicketsReducer } from './slices/onlinetickets';
import { reducer as offlineTicketsReducer } from './slices/offlineTickets';
import { reducer as offlinePhotoTicketsReducer } from './slices/offlinePhotoTickets';
import { reducer as onlinePhotoTicketsReducer } from './slices/onlinePhotoTickets';
import { reducer as declinedModifiedTicketsReducer } from './slices/declinedModifiedTickets';
import { reducer as offlineModifiedTicketsReducer } from './slices/offlineModifiedTickets';
import { reducer as onlineModifiedTicketsReducer } from './slices/onlineModifiedTickets';
import { reducer as trustworthinessReducer } from './slices/trustworthiness';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  return combineReducers({
    base: baseReducer,
    blackList: blackListReducer,
    throwError: throwErrorReducer,
    accountDetails: accountDetailsReducer,
    dictionary: dictionaryReducer,
    messages: messagesReducer,
    watchList: watchListReducer,
    categorySettings: categorySettingsReducer,
    onlineTickets: onlineTicketsReducer,
    offlineTickets: offlineTicketsReducer,
    offlinePhotoTickets: offlinePhotoTicketsReducer,
    onlinePhotoTickets: onlinePhotoTicketsReducer,
    offlineModifiedTickets: offlineModifiedTicketsReducer,
    onlineModifiedTickets: onlineModifiedTicketsReducer,
    declinedModifiedTickets: declinedModifiedTicketsReducer,
    trustworthiness: trustworthinessReducer,
    ...injectedReducers,
  });
}
