import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState = {
	tickets: null,
	filteredTickets: null,
	count: 0,
	profiles: null,
	accounts: null,
	profileAds: null,
};

export const onlineTicketsSlice = createSlice({
	name: 'onlineTickets',
	initialState,
	reducers: {
		saveTickets: (state, action) => {
			state.tickets = action.payload.data;
			state.accounts = action.payload.accounts;
			state.profiles = action.payload.profiles;
			state.count = action.payload.data.length;
			state.profileAds = action.payload.profileAds;
		},
		saveFilteredTickets: (state, action) => {
			state.filteredTickets = action.payload;
		},
	},
});

export const { actions: onlineTicketsActions, reducer } = onlineTicketsSlice;

export const useOnlineTicketsSlice = () => {
	useInjectReducer({ key: onlineTicketsSlice.name, reducer });
	return { actions: onlineTicketsActions };
};
