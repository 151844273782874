import { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Route from './routerWrapper';
import useMediaDevice from 'hooks/use-media-device';
import Login from 'app/pages/Entry/Login';
import Register from 'app/pages/Entry/Register';
import { HomePage } from 'app/pages/Home/Loadable';
import { Search } from 'app/pages/Search/Loadable';
import { NotFoundPage } from 'app/pages/NotFound/Loadable';
import { OnlineTicketsPage } from 'app/pages/OnlineTickets/Loadable';
import { OnlinePhotoTicketsPage } from 'app/pages/OnlinePhotoTickets/Loadable';
import { OnlineModifiedTicketsPage } from 'app/pages/OnlineModifiedTickets/Loadable';
import { OfflineTicketsPage } from 'app/pages/OfflineTickets/Loadable';
import { OfflinePhotoTicketsPage } from 'app/pages/OfflinePhotoTickets/Loadable';
import { OfflineModifiedTicketsPage } from 'app/pages/OfflineModifiedTickets/Loadable';
import { FraudPrevention } from 'app/pages/FraudPrevention/Loadable';
import { Orders } from 'app/pages/Orders/Loadable';
import { BlackList } from 'app/pages/BlackListedWords/Loadable';
import { AccountDetails } from 'app/pages/AccountDetails/Loadable';
import { AdvertiserProfilePage } from 'app/pages/AdvertiserProfile/Loadable';
import { DeclinedModified } from 'app/pages/DeclinedModifiedTickets/Loadable';
import { Messenger } from 'app/pages/Messenger/Loadable';
import { Dictionary } from 'app/pages/Dictionary/Loadable';
import { VerificationsPage } from 'app/pages/Verifications/Loadable';
import { WatchList } from 'app/pages/WatchList/Loadable';
import { CategorySettings } from 'app/pages/CategorySettings/Loadable';
import { SuspectedAccounts } from 'app/pages/SuspectedAccounts/Loadable';
import { Analytics } from 'app/pages/Analytics/Loadable';
import { useBaseSlice } from 'store/slices/baseReducer';

export default function Routes(props) {
  const { deviceType } = useMediaDevice();
  const location = useLocation();
  const { actions } = useBaseSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.toggleAsideMenu(false));
    // should close Aside menu bar if it is open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const restParams = {
    deviceType,
    isLandscape: props.isLandscape,
  };

  return (
    <Switch>
      <Route path="/login" component={Login} {...restParams} />
      <Route path="/register" component={Register} {...restParams} />
      <Route path="/dashboard" component={HomePage} isPrivate {...restParams} />
      <Route path="/search" component={Search} isPrivate {...restParams} />
      <Route path="/online-tickets" component={OnlineTicketsPage} isPrivate {...restParams} />
      <Route
        path="/online-photo-tickets"
        component={OnlinePhotoTicketsPage}
        isPrivate
        {...restParams}
      />
      <Route
        path="/online-modified-tickets"
        component={OnlineModifiedTicketsPage}
        isPrivate
        {...restParams}
      />
      <Route path="/offline-tickets" component={OfflineTicketsPage} isPrivate {...restParams} />
      <Route
        path="/offline-photo-tickets"
        component={OfflinePhotoTicketsPage}
        isPrivate
        {...restParams}
      />
      <Route
        path="/offline-modified-tickets"
        component={OfflineModifiedTicketsPage}
        isPrivate
        {...restParams}
      />
      <Route path="/orders" component={Orders} isPrivate {...restParams} />
      <Route path="/suspected-accounts" component={SuspectedAccounts} isPrivate {...restParams} />
      <Route path="/black-listed-words" component={BlackList} isPrivate {...restParams} />
      <Route path="/verifications" component={VerificationsPage} isPrivate {...restParams} />
      <Route path="/declinedModified" component={DeclinedModified} isPrivate {...restParams} />
      <Route path="/messages" component={Messenger} isPrivate {...restParams} />
      <Route path="/dictionary" component={Dictionary} isPrivate {...restParams} />
      <Route
        path="/advertiser/profile/:id"
        component={AdvertiserProfilePage}
        isPrivate
        {...restParams}
      />
      <Route path="/account-details/:_id" component={AccountDetails} isPrivate {...restParams} />
      <Route path="/watch-list" component={WatchList} isPrivate {...restParams} />
      <Route path="/category-settings" component={CategorySettings} isPrivate {...restParams} />
      <Route path="/fraud-prevention" component={FraudPrevention} isPrivate {...restParams} />
      {process.env.REACT_APP_PRIVATE_ROUTE === 'analytics' && (
        <Route path="/analytics" component={Analytics} isPrivate {...restParams} />
      )}
      <Route path="/analytics" component={Analytics} isPrivate {...restParams} />
      <Route component={NotFoundPage} path="/404" isPrivate {...restParams} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
