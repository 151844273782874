import io from 'socket.io-client';
import { useEffect } from 'react';
import { baseActions } from 'store/slices/baseReducer';
import options from 'config';
import cookies from 'js-cookie';

const actions = {
  ticketApproved: (dispatch, data) => dispatch(baseActions.setApprovedTicket(data)),
  policeClaim: (dispatch, data) => dispatch(baseActions.setPoliceClaimUrl(data.url)),
  invoicePdf: (dispatch, data) => {
    const { url } = data;
    const linkUrl = `${url}?token=${cookies.get('authToken')}`;
    const link = document.createElement('a');
    link.href = linkUrl;
    link.download = url.split('/').pop();
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

const useSocketIo = store => {
  const { dispatch /* getState */ } = store;
  const token = cookies.get('authToken');

  useEffect(() => {
    if (token) {
      const ioCellar = io(options.WEB_SOCKET_URL, {
        query: {
          token: token,
        },
        withCredentials: true,
        transports: ['websocket'],
      });

      ioCellar.on('connect', () => {
        console.log(
          '%c[open] Connection established',
          'background: #079992; color: #ffffff; padding: 3px 5px; border-radius: 3px',
        );
      });

      ioCellar.on('cellar:socket', ({ data, type } = {}) => {
        actions[type]?.(dispatch, data);
      });

      ioCellar.on('disconnect', () => {
        console.log(
          '%c[close] Connection died',
          'background: #c0392b; color: #ffffff; padding: 3px 5px; border-radius: 3px',
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useSocketIo;
