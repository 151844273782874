import { all, fork } from 'redux-saga/effects';
import BlackListedWords from './blackListedWords';
import trowErrorSaga from './throwError';
import getAccountDetailsSaga from './accountDetails';
import dictionarySaga from './dictionary';
import loginSaga from './login';
import getMessagesList from './messages';
import watchList from './watchList';
import categorySettings from './categorySettings';
import onlineTicketsSaga from './onlineTickets';
import offlineTicketsSaga from './offlineTickets';
import offlinePhotoTicketsSaga from './offlinePhotoTickets';
import onlinePhotoTicketsSaga from './onlinePhotoTickets';
import declinedModifiedTicketsSaga from './declinedModifiedTickets';

export default function* rootSaga() {
  yield all([
    fork(BlackListedWords),
    fork(trowErrorSaga),
    fork(getAccountDetailsSaga),
    fork(dictionarySaga),
    fork(onlinePhotoTicketsSaga),
    fork(loginSaga),
    fork(getMessagesList),
    fork(watchList),
    fork(categorySettings),
    fork(onlineTicketsSaga),
    fork(offlineTicketsSaga),
    fork(declinedModifiedTicketsSaga),
    fork(offlinePhotoTicketsSaga),
  ]);
}
