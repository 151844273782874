import { call, put, takeEvery, all } from 'redux-saga/effects';
import { categorySettingsService, HttpRequest } from 'services';
import { Types } from './types/index';
import { categorySettingsActions } from 'store/slices/categorySettings';
import { throwErrorActions } from 'store/slices/throwError';

export function* getCategorySettings() {
  try {
    let { data } = yield call(() =>
      (async () => {
        return await categorySettingsService.getCategories();
      })(),
    );

    if (data.categories) {
      yield put(categorySettingsActions.saveCategories(data.categories));
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* filterCategories(props) {
  yield put(categorySettingsActions.filterCategories(props.value));
}

export function* getCategoryDraft(props) {
  try {
    let { data } = yield call(() =>
      (async () => {
        return await categorySettingsService.getPrices(props.id);
      })(),
    );

    if (data) {
      yield put(
        categorySettingsActions.saveCategoriesParams({
          data: {
            prices: data ? data.prices : null,
          },
          id: props.id,
        }),
      );
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* updateCategory(props) {
  try {
    let data = yield call(() =>
      (async () => {
        return await Promise.allSettled([
          HttpRequest.patch(`/inventory/categories/${props.id}`, props.categoryData),
          HttpRequest.patch(`/inventory/prices`, props.priceData),
        ]);
      })(),
    );

    let result = { category: null, price: null, id: props.id };

    if (data[0].status === 'fulfilled') {
      result.category = props.categoryData.data;
    }
    if (data[1].status === 'fulfilled') {
      result.price = props.priceData.data;
    }

    yield put(categorySettingsActions.updateList(result));

    setTimeout(() => {
      props.cd(false);
    }, 1000);

    if (data[0].status === 'fulfilled' && data[1].status === 'fulfilled') {
      yield put(throwErrorActions.updateSuccess('The changes have been successfully saved'));
    } else {
      // eslint-disable-next-line no-throw-literal
      throw { message: 'Error' };
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
    setTimeout(() => {
      props.cd(false);
    }, 1000);
  }
}

export default function* categorySettings() {
  yield all([
    takeEvery(Types.GET_CATEGORY_SETTINGS, getCategorySettings),
    takeEvery(Types.SAVE_CATEGORY_DRAFT, getCategoryDraft),
    takeEvery(Types.FILTER_CATEGORIES, filterCategories),
    takeEvery(Types.UPDATE_CATEGORY_SETTINGS, updateCategory),
  ]);
}
