import { useDispatch, useSelector } from 'react-redux';
import AsideMenu from 'app/components/Smart/AsideMenu';
import { Header } from 'app/components/Shared';
import { DashboardWrapper, MainSection } from 'styles/global-styles';
import { useBaseSlice } from 'store/slices/baseReducer';
import { baseSelector } from 'store/reselect';

const AuthLayout = ({ children, deviceType, isLandscape }) => {
  const { actions } = useBaseSlice();
  const dispatch = useDispatch();
  const { isOpenAsideMenu } = useSelector(baseSelector);
  const isMobileView = deviceType === 'mobile' && !isLandscape;
  return (
    <DashboardWrapper>
      <AsideMenu
        isOpen={isOpenAsideMenu}
        emitOpen={v => dispatch(actions.toggleAsideMenu(v))}
        isMobileView={isMobileView}
      />
      <MainSection isOpen={isOpenAsideMenu} isMobileView={isMobileView}>
        <Header isMobileView={isMobileView} />
        <main id="main">{children}</main>
      </MainSection>
    </DashboardWrapper>
  );
};

export default AuthLayout;
