import { useEffect, useState } from 'react';
import * as React from 'react';
import { BREAKPOINTS, DEVICE_CHECKING } from 'utils/constants';

const getWidth = (width) => {
	const keys= Object.keys(BREAKPOINTS.keys).reverse();
	const devices= keys.filter(
		key => width <= BREAKPOINTS.keys[key],
	);
	return devices.pop() || 'desktop';
};

export default function useMediaDevice() {
	const _width = window.innerWidth;
	const [deviceSize, setDeviceSize] = useState(getWidth(_width));
	const [windowWidth, setWindowWidth] = useState(_width);
	const [deviceType, setDeviceType] = useState(null);

	function changeWindowSize() {
		setWindowWidth(window.innerWidth);
	}

	useEffect(() => {
		const _winSize = window.innerWidth;
		const _winType = getWidth(_winSize);
		if (deviceSize !== _winType) {
			setDeviceSize(_winType);
		}
		if (_winSize !== windowWidth) {
			changeWindowSize();
		}
		if (BREAKPOINTS.keys[_winType] <= DEVICE_CHECKING.mobile)
			setDeviceType('mobile');
		else if (
			BREAKPOINTS.keys[_winType] >= DEVICE_CHECKING.tablet[0] &&
			BREAKPOINTS.keys[_winType] <= DEVICE_CHECKING.tablet[1]
		)
			setDeviceType('tablet');
		else setDeviceType('desktop');
	}, [deviceSize, windowWidth]);

	React.useEffect(() => {
		window.addEventListener('resize', changeWindowSize);

		return () => {
			window.removeEventListener('resize', changeWindowSize);
		};
	}, []);

	return {
		windowWidth,
		deviceSize,
		deviceType,
	};
}
