import { memo } from 'react';
import { Logo } from './styles';

const ShortLogo = ({ click }) => {
	return (
		<Logo onClick={() => click?.()} cursorProp={!!click}>
			S
		</Logo>
	);
};
export default memo(ShortLogo);
