import { HttpRequest } from 'services';

export const trustworthiness = {
  getIps: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/ips`);
  },
  getFaceDetection: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/face`);
  },

  getCookies: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/cookies`);
  },
  getPhones: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/phones`);
  },
  getLastVerificationStatus: body => {
    return HttpRequest.post('profile-verification/verification/status/bulk', body);
  },
  getWebsites: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/websites`);
  },
  getCounts: (accountId = '339') => {
    return HttpRequest.get(`/fraud/accounts/${accountId}/match/count`);
  },
  updateToggle: (id = '339', account_id, checked) => {
    return HttpRequest.put(`/fraud/${id}`, { checked, account_id });
  },
  getYellowDoth: (id = '339') => {
    return HttpRequest.get(`fraud/active_match?account_id=${id}`);
  },
  deleteMatches: id => {
    return HttpRequest.delete(`fraud/${id}`);
  },
};
