export const CategoryTypes = [
  { title: 'All categories', value: '*' },
  { title: 'Category 1', value: 'category-1' },
  { title: 'Category 2', value: 'category-2' },
  { title: 'Category 3', value: 'category-3' },
  { title: 'Category 4', value: 'category-4' },
];

export const VerificationTypes = [
  { title: 'All', value: 'all' },
  { title: 'ID', value: 'id' },
  { title: 'Person', value: 'person' },
];

export const Actions = [
  { title: 'Approve', value: 'Approve' },
  {
    title: 'You have placed contact information in your name, title or text',
    value: 'you_have_placed_contact_inform',
  },
  {
    title: 'You have placed place names in your name, title or text',
    value: 'you_have_placed_place_names_in',
  },
  {
    title: 'You have placed your age in your name, title or text',
    value: 'you_have_placed_your_age_in_yo',
  },
  {
    title: 'You have placed irrelevant characters in your name, title or text',
    value: 'you_have_placed_irrelevant_cha',
  },
  {
    title: 'No recruitment of ladies allowed',
    value: 'no_recruitment_of_ladies_allow',
  },
  {
    title: 'Your ad contains inconsistencies',
    value: 'your_ad_contains_inconsistenci',
  },
  {
    title: 'Doubt about the correctness of the spoken languages',
    value: 'doubt_about_the_correctness_of_lg',
  },
  {
    title: 'Doubt about the correctness of  your origin',
    value: 'doubt_about_the_correctness_of',
  },
  { title: 'Your age not realistic', value: 'your_age_not_realistic' },
  {
    title: 'Website must be directly linked to your advertisement',
    value: 'website_must_be_directly_linke',
  },
  {
    title: 'Your change is not following the rules',
    value: 'your_ad_is_not_following_the_r',
  },
  {
    title: 'Only a name is allowed in the name field',
    value: 'only_a_name_is_allowed_in_the_',
  },
  {
    title: 'Prices can be filled in the pricefield',
    value: 'prices_can_be_filled_in_the_pr',
  },
  {
    title: 'Your ad contains too much capitals',
    value: 'your_ad_contains_too_much_capi',
  },
  {
    title: 'There is a ban on physical agreements. It is not allowed to actively promote this',
    value: 'there_is_a_ban_on_physical_agr',
  },
];

export const AccountTypes = [
  { title: 'Single', value: 'single' },
  { title: 'Couple', value: 'couple' },
  { title: 'Company', value: 'company' },
];

export const MatchTypes = [
  { title: 'Scam 100', value: 'scam_100' },
  { title: 'Scam Suspicion', value: 'scam_suspicion' },
  { title: 'Phishing', value: 'phishing' },
  { title: 'Face match', value: 'face' },
];

export const GenderTypes = [
  { title: 'All genders', value: 'all' },
  { title: 'Male', value: 'male' },
  { title: 'Female', value: 'female' },
  { title: 'Shemale', value: 'shemale' },
];

export const AdTypes = [
  { title: 'All advertisement types', value: '*' },
  { title: 'Ad 1', value: 'ad-1' },
  { title: 'Ad 2', value: 'ad-2' },
  { title: 'Ad 3', value: 'ad-3' },
];

export const Categories = [
  { title: 'All categories', value: 'all' },
  { title: 'Events', value: 'events' },
  { title: 'Vacancies', value: 'vacancies' },
  { title: 'Ladies of pleasure', value: 'ladiesOfPleasure' },
  { title: 'Erotic massage', value: 'eroticMassage' },
  { title: 'SM', value: 'sm' },
  { title: 'Cam & phonesex', value: 'camAndPhonesex' },
  { title: 'Men', value: 'men' },
  { title: 'Payment in kind', value: 'paymentInKind' },
  { title: 'Free sex contact', value: 'freeSexContact' },
  { title: 'Sex items', value: 'sexItems' },
  { title: 'Gay & bi', value: 'gayAndBi' },
  { title: 'Shemale', value: 'shemale' },
  { title: 'Misc', value: 'misc' },
];

export const CategoryIdToDate = {
  1: { name: 'events', title: 'Events' },
  2: { name: 'vacancies', title: 'Vacancy' },
  3: { name: 'ladies_of_pleasure', title: 'Ladies of pleasure' },
  4: { name: 'erotic_massage', title: 'Erotic massage' },
  5: { name: 'sm', title: 'SM' },
  6: { name: 'cam_phonesex', title: 'Cam & Phonesex' },
  7: { name: 'men', title: 'Men' },
  8: { name: 'payment_in_kind', title: 'Payment in Kind' },
  9: { name: 'free_sex_contact', title: 'Free Sex Contact' },
  10: { name: 'sex_items', title: 'Sex items' },
  11: { name: 'gay_bi', title: 'Gay & Bi' },
  13: { name: 'misc', title: 'Misc' },
};

export const FiltersMapping = {
  all: 'All categories',
  events: 'Events',
  vacancies: 'Vacancies',
  ladiesOfPleasure: 'Ladies of pleasure',
  eroticMassage: 'Erotic massage',
  sm: 'SM',
  camAndPhonesex: 'Cam & phonesex',
  men: 'Men',
  paymentInKind: 'Payment in kind',
  freeSexContact: 'Free sex contact',
  sexItems: 'Sex items',
  gayAndBi: 'Gay & bi',
  shemale: 'shemale',
  misc: 'Misc',
  single: 'single',
  couple: 'couple',
  company: 'company',
  male: 'male',
  female: 'female',
};

export const BREAKPOINTS = {
  between: {
    xss_xs: { min: 321, max: 480 },
    xs_sm: { min: 481, max: 768 },
    sm_md: { min: 769, max: 1024 },
    md_lg: { min: 1025, max: 1440 },
    lg_xl: { min: 1441, max: 1920 },
  },
  keys: {
    xss: 480,
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
  },
};

export const DEVICE_CHECKING = {
  mobile: 768,
  tablet: [769, 1440],
  desktop: 1025,
};

export const phoneCodeOptions = [
  {
    value: '+31',
    title: '+31',
  },
  {
    value: '+32',
    title: '+32',
  },
  {
    value: '+49',
    title: '+49',
  },
];
