import styled from 'styled-components';
import { StyleConstants } from 'styles/StyleConstants';

export const TooltipContent = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: 6px;
  color: ${StyleConstants.TOOLTIP_TEXT_COLOR};
  background: ${props => props.bg};
  font-size: ${StyleConstants.TEXT_SIZE};
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  opacity: ${props => props.opacity};
  max-width: ${props => props.maxWidth};
  left: ${props => props.position.left}px;
  top: ${props => props.position.top}px;
  &::before {
    content: ' ';
    left: 50%;
    border: 6px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-left: -6px;
  }
  &.top::before {
    top: 100%;
    border-top-color: ${props => props.bg};
  }
  &.right::before {
    left: -5px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${props => props.bg};
  }
  &.bottom::before {
    bottom: 100%;
    border-bottom-color: ${props => props.bg};
  }
  &.left::before {
    left: auto;
    right: -11px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${props => props.bg};
  }
`;
