/**
 * Asynchronously loads the component for WatchList
 */

import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/Shared/LoadingIndicator';

export const WatchList = lazyLoad(
	() => import('./index'),
	module => module.WatchList,
	{
		fallback: <LoadingIndicator />,
	},
);
