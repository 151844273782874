import { format } from 'date-fns';
const capitalize = str => {
  if (typeof str !== 'string') return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

const uppercase = str => {
  if (typeof str !== 'string') return str;

  return str.toUpperCase();
};
export const getApproveData = (item, name) => {
  if (!item?.[name]) return '';
  let value = item[name]
    ? item[name]
    : item.hasOwnProperty('_changes') &&
      item._changes.hasOwnProperty('fields') &&
      item._changes.fields.hasOwnProperty(name) &&
      item._changes.fields[name][0].value.value;

  value = capitalize(value);

  if (name === 'date_of_birth') {
    value = format(new Date(value), 'dd-MM-yyyy');
  }

  if (name === 'cup_size') {
    value = uppercase(value);
  }

  return value ? value : '';
};

export const getChangesData = data =>
  Object.keys(data?._changes?.fields || {}).reduce((acc, name) => {
    const changedResult = data._changes?.fields?.[name]?.[0];

    if (Array.isArray(data[name])) {
      acc[name] =
        changedResult && changedResult.status !== 'declined'
          ? data._changes?.fields?.[name]?.map(({ value }) => ({
              id: value.id,
              name: value.value,
              value: value.value,
            }))
          : data[name];
    } else {
      acc[name] =
        changedResult && changedResult.status !== 'declined'
          ? changedResult.value.value
          : data[name] || '';
    }

    return acc;
  }, {});

export const handleProfileAd = data => {
  const newData = {
    dataForUpdate: {
      previousData: { ...data },
      ...data,
      ...getChangesData(data),
    },
    _meta: data?._meta,
    global_status: data?._changes.status,
  };

  return newData;
};
