import { createAsyncThunk } from '@reduxjs/toolkit';
import { throwErrorActions } from 'store/slices/throwError';

export const asyncAction = (name, callback) =>
  createAsyncThunk(name, async (params, { dispatch, getState }) => {
    try {
      const res = await callback(params, dispatch, getState);
      return res;
    } catch (e) {
      dispatch(throwErrorActions.updateMessage({ message: e.response?.data.message }));
    }
  });
