import { HttpRequest } from 'services';

export const blacklistApiService = {
  getList: params => {
    return HttpRequest.get('/blacklist', { params });
  },

  getBlacklistMatch: () => {
    return HttpRequest.get('/blacklist/match');
  },

  search: (data, params) => {
    return HttpRequest.post('/blacklist/search', data, { params });
  },

  createBlackList: data => {
    return HttpRequest.post('/blacklist', data);
  },

  editBlackList: (data, id) => {
    return HttpRequest.put(`/blacklist/${id}`, data);
  },

  deleteItem: id => {
    return HttpRequest.delete(`/blacklist/${id}`);
  },
};
