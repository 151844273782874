/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import cookies from 'js-cookie';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useMount from 'hooks/useMount';
import { TranslatorProvider } from 'utils/translator';
import CssBaseline from '@material-ui/core/CssBaseline';
import ToastifyError from 'app/components/Shared/ToastifyError';
import ToastifySuccess from 'app/components/Shared/ToastifySuccess';

import useSocketIo from 'hooks/useSocketIo';
import { accountDetailsService } from 'services';
import { trowErrorSelector } from 'store/reselect';
import { accountDetailsActions, getAccountConfig } from 'store/slices/accountDetails';
import { getProfileAdConfigs } from 'store/slices/baseReducer';
import { getBlacklistMatch } from 'store/slices/blackListReducer';

import { GlobalStyle } from 'styles/global-styles';

import Routes from './routes';

export function App(props) {
  const state = useSelector(trowErrorSelector);
  const dispatch = useDispatch();

  useSocketIo({ dispatch });

  useMount(() => {
    if (cookies.get('authToken')) {
      dispatch(getBlacklistMatch());
    }
  });

  useMount(() => {
    (async () => {
      const { data } = await accountDetailsService.getVerificationConfig();
      dispatch(accountDetailsActions.saveVerificationConfig(data));
      const { data: profileConfig } = await accountDetailsService.getProfileConfigs();
      dispatch(accountDetailsActions.saveProfileCon(profileConfig));
      dispatch(getAccountConfig());
      dispatch(getProfileAdConfigs());
    })();
  });

  return (
    <BrowserRouter>
      <TranslatorProvider>
        <Helmet
          defaultTitle="SexJobs :: Cellar"
          titleTemplate="%s - SexJobs :: Cellar"
          htmlAttributes={{ lang: 'en' }}
        >
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no"
          />
          <meta name="description" content="SexJobs :: Cellar" />
        </Helmet>
        {state.message && <ToastifyError ErrorMessage={state.message} />}

        {state.success && <ToastifySuccess successMessage={state.success} />}

        <Routes {...props} />
        <CssBaseline />
        <GlobalStyle />
        <div id="tooltip-container"></div>
      </TranslatorProvider>
    </BrowserRouter>
  );
}
