import { call, put, takeEvery, all } from 'redux-saga/effects';
import { messagesService } from 'services';
import { Types } from './types/index';
import { messagesActions } from 'store/slices/messages';
import { throwErrorActions } from 'store/slices/throwError';

export function* getMessagesList(props) {
  try {
    let result = yield call(() =>
      (async () => {
        return await messagesService.getList({ ...props.query });
      })(),
    );

    yield put(messagesActions.saveMessages(result.data));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* getReportChat(props) {
  if (props.reset) {
    yield put(messagesActions.resetChat());
  } else {
    try {
      const threadResult = yield call(() =>
        (async () => {
          return await messagesService.getThread(props.threadId);
        })(),
      );

      if (props.typeOfChat === 'thread') {
        const { data } = yield call(() =>
          (async () => {
            return await messagesService.getReportChatByThreadId({
              id: props.threadId,
              params: {
                _page: props.page,
                _limit: props.limit,
              },
            });
          })(),
        );

        yield put(
          messagesActions.saveReportChat({
            reportChat: data.result,
            threadRes: threadResult.data,
            page: props.page,
          }),
        );
      } else {
        const result = yield call(() =>
          (async () => {
            return await messagesService.getReportChat({
              ...props.query,
            });
          })(),
        );

        if (!props.query.partition) {
          const newRes = [];
          result.data[0].forEach(element => {
            newRes.unshift(element);
          });

          yield put(
            messagesActions.saveReportChat({
              reportChat: [newRes, result.data[1]],
              threadRes: threadResult.data,
              page: props.page,
            }),
          );
        } else {
          yield put(
            messagesActions.savePartitionChat({
              reportChat: result.data,
              partition: props.query.partition,
            }),
          );
        }
      }

      setTimeout(() => {
        props.setGetMessLoad(false);
      }, 500);
    } catch (err) {
      yield put(throwErrorActions.updateMessage({ message: err.message }));
      props.setGetMessLoad(props.query.partition ? props.query.partition : true);
    }
  }
}

export function* changeReportViewd(props) {
  yield put(messagesActions.changeViewd(props.index));
}

export default function* dictionarySaga() {
  yield all([
    takeEvery(Types.GET_MESSAGES_LIST, getMessagesList),
    takeEvery(Types.GET_REPORT_CHAT, getReportChat),
    takeEvery(Types.CHANGE_REPORT_VIEWED, changeReportViewd),
  ]);
}
