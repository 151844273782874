import { createMuiTheme } from '@material-ui/core/styles';
import { StyleConstants } from 'styles/StyleConstants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#28C0A3',
    },
  },
  overrides: {
    MuiFormControlLabel: {
      root: {
        marginRight: '0',
        color: StyleConstants.TEXT_COLOR,
        '& .MuiTypography-body1': {
          fontSize: StyleConstants.TEXT_SIZE,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#C5CAD9',
        '&.Mui-checked': {
          color: '#F2C64F !important',
        },
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: 'none',
        paddingTop: '25px !important',
      },
    },
    MuiButton: {
      root: {
        height: '40px',
        fontSize: StyleConstants.BUTTON_TEXT_SIZE,
        borderRadius: '3px',
        textTransform: 'initial',
        fontWeight: 400,
        boxShadow: 'none !important',
        padding: '10px 20px',
      },
      containedPrimary: {
        color: '#fff',
      },
      sizeSmall: {
        height: '30px',
      },
    },
    MuiTableCell: {
      head: {
        color: StyleConstants.CELL_HEAD_COLOR,
        fontSize: StyleConstants.CELL_HEAD_TXT_SIZE,
        whiteSpace: 'nowrap',
      },
      body: {
        color: StyleConstants.CELL_BODY_COLOR,
        whiteSpace: 'nowrap',
        fontSize: StyleConstants.CELL_BODY_TXT_SIZE,
        '& a': {
          color: 'inherit',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: StyleConstants.INPUT_TEXT_SIZE,
      },
    },
    MuiSelect: {
      select: {
        borderRadius: StyleConstants.INPUT_RADIUS,
        '&:focus': {
          borderRadius: StyleConstants.INPUT_RADIUS,
          backgroundColor: StyleConstants.INPUT_BG,
        },
      },
      root: {
        padding: '10px 0',
        border: '1px solid ' + StyleConstants.INPUT_BORDER_COLOR,
        backgroundColor: StyleConstants.INPUT_BG,
        paddingLeft: '10.5px',
        fontSize: StyleConstants.INPUT_TEXT_SIZE,
        color: StyleConstants.INPUT_COLOR,
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: StyleConstants.INPUT_DISABLED_COLOR,
          backgroundColor: StyleConstants.INPUT_DISABLED_BG,
        },
        '&.select_size__sm .MuiSelect-root': {
          paddingTop: '5px',
          paddingBottom: '5px',
          height: '32px',
          boxSizing: 'border-box',
          lineHeight: '20px',
        },
        '&::before, &::after': {
          display: 'none',
        },
      },
      inputMarginDense: {
        height: '32px',
        padding: '0px 10px !important',
        fontSize: StyleConstants.INPUT_TEXT_SIZE,
      },
    },
    MuiOutlinedInput: {
      multiline: {
        padding: '0',
      },
      notchedOutline: {
        borderColor: StyleConstants.INPUT_BORDER_COLOR + ' !important',
      },
    },
  },
});

export default theme;
