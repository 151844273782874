import { createSlice } from 'utils/@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { throwErrorActions } from 'store/slices/throwError';
import { trustworthiness } from 'services/trustworthiness';
import { accountDetailsService } from 'services';

export const initialState = {
  banned: {
    isLoading: true,
    data: null,
  },
  ip: {
    isLoading: true,
    data: null,
  },
  cookie: {
    isLoading: true,
    data: null,
  },
  face: {
    isLoading: true,
    data: null,
  },
  phone: {
    isLoading: true,
    data: null,
  },
  url: {
    isLoading: true,
    data: null,
  },
};

const asyncAction = (name, callback) =>
  createAsyncThunk(name, async (params, { dispatch }) => {
    try {
      const res = await callback(params, dispatch);
      return res;
    } catch (e) {
      dispatch(throwErrorActions.updateMessage({ message: e.response?.data.message }));
    }
  });

export const getTrustworthinessData = asyncAction(
  'trustworthinessSections/trustworthinessData',
  async payload => {
    const { data } = await trustworthiness[payload.action](payload.accountId);

    const ids = data.map(item => item.account_id);

    const { data: accountsData } = await accountDetailsService.getAccountsBulk({ ids });

    const { data: profilesData } = await accountDetailsService.getProfilesBulkByAccountIds({
      account_ids: ids,
    });

    const result = data.map(cookie => ({
      ...cookie,
      accountData: accountsData.find(({ id }) => id === cookie.account_id),
      profileData: profilesData.filter(({ _meta }) => _meta.account_id === cookie.account_id),
    }));

    return { name: payload.name, result };
  },
);

export const trustworthinessSectionsSlice = createSlice({
  name: 'trustworthinessSections',
  initialState,
  reducers: {
    resetTrustworthiness: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getTrustworthinessData.fulfilled, (state, action) => {
      if (action.payload) {
        state[action.payload.name] = { data: action.payload.result, isLoading: false };
      }
    });
  },
});

export const { actions: trustworthinessSectionsActions, reducer } = trustworthinessSectionsSlice;
