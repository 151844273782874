import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { throwErrorActions } from 'store/slices/throwError';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ToastifyError({ ErrorMessage }) {
  const dispatch = useDispatch();

  toast.error(ErrorMessage, {
    onClose: () => {
      dispatch(throwErrorActions.updateMessage({ message: '' }));
    },
  });

  return <ToastContainer />;
}

export default memo(ToastifyError);
