import { HttpRequest } from 'services';

export const mediaService = {
  getImage: image_id => {
    return HttpRequest.get(`/media/image/${image_id}`);
  },
  getBulkImages: data => {
    return HttpRequest.post('/media/images/bulk', data);
  },

  updateImage: data => {
    const { imageId, files, replace } = data;
    return HttpRequest.post(`/media/image/${imageId}?replace=${replace}`, files);
  },
  rotateImage: data => {
    const { imageId, rotation } = data;
    return HttpRequest.post(`/media/images/rotate/${imageId}`, rotation);
  },
};
