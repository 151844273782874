export const Types = {
  //Black list
  GET_BLACKLIST: 'GET_BLACKLIST',
  CREATE_BLACK_LIST_ITEM: 'CREATE_BLACK_LIST_ITEM',
  EDIT_BLACK_LIST_ITEM: 'EDIT_BLACK_LIST_ITEM',
  SEARCH_BLACK_LIST: 'SEARCH_BLACK_LIST',

  //TrowError
  THROW_ERROR: 'THROW_ERROR',

  //account-detalis
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  RESET_DATA: 'RESET_DATA',
  CHECK_VERIFICATION_CONTACT: 'CHECK_VERIFICATION_CONTACT',
  SEND_VERIFICATION_CONTACT: 'SEND_VERIFICATION_CONTACT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_MEMO: 'RESET_MEMO',
  //logs
  GET_ACTIVITY_LOGS: 'GET_ACTIVITY_LOGS',
  CREATE_ACTIVITY_LOGS: 'CREATE_ACTIVITY_LOGS',
  //profiles
  GET_PROFILES: 'GET_PROFILES',
  VERIFICATION_PERSON_OR_ID: 'VERIFICATION_PERSON_OR_ID',
  SAVE_PROFILE_IMAGES: 'SAVE_PROFILE_IMAGES',
  APPROVE_OR_DECLINE: 'APPROVE_OR_DECLINE',
  PIN_PHOTO: 'PIN_PHOTO',
  DELETE_PROFILE_VERIFICATION_PHOTO: 'DELETE_PROFILE_VERIFICATION_PHOTO',

  //dictionaru
  GET_DICTIONARY_LIST: 'GET_DICTIONARY_LIST',
  FILTER_DICTIONARY_LIST: 'FILTER_DICTIONARY_LIST',

  //messages
  GET_MESSAGES_LIST: 'GET_MESSAGES_LIST',
  GET_REPORT_CHAT: 'GET_REPORT_CHAT',
  CHANGE_REPORT_VIEWED: 'CHANGE_REPORT_VIEWED',

  //watch list

  GET_WATCH_LIST: 'GET_WATCH_LIST',
  DELETE_WATCH_LIST: 'DELETE_WATCH_LIST',
  EDIT_WATCH_LIST: 'EDIT_WATCH_LIST',

  //category settings

  GET_CATEGORY_SETTINGS: 'GET_CATEGORY_SETTINGS',
  SAVE_CATEGORY_DRAFT: 'SAVE_CATEGORY_DRAFT',
  FILTER_CATEGORIES: 'FILTER_CATEGORIES',
  UPDATE_CATEGORY_SETTINGS: 'UPDATE_CATEGORY_SETTINGS',

  //login
  LOGIN: 'LOGIN',

  //online tickets
  GET_ONLINE_TICKETS: 'GET_ONLINE_TICKETS',
  FILTER_TICKETS: 'FILTER_TICKETS',

  // offline tickets
  GET_OFFLINE_TICKETS: 'GET_OFFLINE_TICKETS',

  // offline photo tickets
  GET_OFFLINE_PHOTO_TICKETS: 'GET_OFFLINE_PHOTO_TICKETS',

  // online photo tickets
  GET_ONLINE_PHOTO_TICKETS: 'GET_ONLINE_PHOTO_TICKETS',

  // declined modified tickets
  GET_DECLINED_MODIFIED_TICKETS: 'GET_DECLINED_MODIFIED_TICKETS',

  // offline modified tickets
  GET_OFFLINE_MODIFIED_TICKETS: 'GET_OFFLINE_MODIFIED_TICKETS',
  GET_ONLINE_MODIFIED_TICKETS: 'GET_ONLINE_MODIFIED_TICKETS',

  //ad
  GET_PROFILE_ADD_DETAILS: 'GET_PROFILE_ADD_DETAILS',
  GET_PROFILE_BY_ID: 'GET_PROFILE_BY_ID',
  UPDATE_PROFILE_AD: 'UPDATE_PROFILE_AD',
  GET_PROFILE_AD_PHOTOS: 'GET_PROFILE_AD_PHOTOS',
  GET_PROFILE_VER_AD_INFO: 'GET_PROFILE_VER_AD_INFO',
};
