import { HttpRequest } from './axiosEnv';

export const loginService = {
  login: loginParams => {
    return HttpRequest.post('/account/tokens/issue', {
      ...loginParams,
      user_agent:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36',
      lifetime: 72,
    });
  },
};

export const getAuthTokens = async data => await HttpRequest.post('account/auth/tokens', data);

export const getAuthRefreshToken = async token =>
  await HttpRequest.post('account/auth/refresh', undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
