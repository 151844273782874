import { call, put, takeEvery, all } from 'redux-saga/effects';
import { dictionaryService } from 'services';
import { Types } from './types/index';
import { dictionaryActions } from 'store/slices/dictionary';
import { throwErrorActions } from 'store/slices/throwError';

export function* getDictionaryList(props) {
  if (props.query) {
    try {
      let result = yield call(() =>
        (async () => {
          return await dictionaryService.getList(props.query);
        })(),
      );

      yield put(dictionaryActions.saveDictionary(result.data));
    } catch (err) {
      yield put(throwErrorActions.updateMessage({ message: err.message }));
    }
  } else {
    yield put(dictionaryActions.saveDictionary(props.data));
  }
}

export function* filterDictionaryList(props) {
  yield put(
    dictionaryActions.filterDictionary({
      data: props.data,
      hasFilter: props.hasFilter,
    }),
  );
}

export default function* dictionarySaga() {
  yield all([
    takeEvery(Types.GET_DICTIONARY_LIST, getDictionaryList),
    takeEvery(Types.FILTER_DICTIONARY_LIST, filterDictionaryList),
  ]);
}
