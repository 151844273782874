/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/Shared/LoadingIndicator';

export const HomePage = lazyLoad(
	() => import('./index'),
	module => module.HomePage,
	{
		fallback: <LoadingIndicator />,
	},
);
