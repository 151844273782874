import { put, call, takeEvery, all } from 'redux-saga/effects';
import { Types } from './types/index';
import { onlineTickets } from 'services';
import { declinedModifiedTicketsActions } from 'store/slices/declinedModifiedTickets';
import { throwErrorActions } from 'store/slices/throwError';

export function* getDeclinedModifiedList(props) {
  try {
    const {
      data: { result, count },
      message,
    } = yield call(() =>
      (async () => {
        return await onlineTickets.getList('declined_modified', 'offline');
      })(),
    );

    if (message) {
      throw new Error(message);
    }

    const accountIds = new Set([]);
    const profileIds = new Set([]);
    const adIds = new Set([]);

    result.forEach(element => {
      accountIds.add(Number(element.account_id));
      if (element.profile_id) {
        profileIds.add(element.profile_id);
      }
      if (element.ad_id) {
        adIds.add(element.ad_id);
      }
    });

    const reqValues = yield call(() =>
      (async () => {
        return await Promise.allSettled([
          onlineTickets.getAccountOrProfiles(Array.from(accountIds), '/account'),
          onlineTickets.getAccountOrProfiles(Array.from(profileIds), '/profile'),
          onlineTickets.getProfileAds(Array.from(adIds)),
        ]);
      })(),
    );

    const accounts = {};
    const profiles = {};
    const profileAds = { categories: {} };

    if (reqValues[2].status === 'fulfilled') {
      reqValues[2].value.data.profileAds.forEach(el => {
        if (el.type) {
          profileAds.categories[el.type] = profileAds.categories[el.type]
            ? profileAds.categories[el.type] + 1
            : 1;
          profileAds[el._id] = el.category_id;
        }
      });
    }

    if (reqValues[0].status === 'fulfilled') {
      reqValues[0].value.data.forEach(account => {
        accounts[account.id] = account.role;
      });
    }

    if (reqValues[1].status === 'fulfilled') {
      reqValues[1].value.data.forEach(profile => {
        profiles[profile._id] = profile.gender ? profile.gender : '-';
      });
    }

    // if (props.defaultPreset.length > 1 || Object.keys(props.filterState).find(el => props.filterState[el].length)) {

    //   const filterData = data.filter(ticket => {
    //     if (props.filterState.categories) {
    //       if (ticket.type !== props.filterState.categories) {
    //         return false;
    //       };
    //     };
    //     if (props.filterState.types) {
    //       if (ticket.accounts[ticket.account_id] !== props.filterState.types) {
    //         return false;
    //       };
    //     };
    //     if (props.filterState.genders) {
    //       console.log(profiles[ticket.profile_id]);

    //       if (profiles[ticket.profile_id] !== props.filterState.genders) {
    //         return false;
    //       };
    //     };
    //     return true;
    //   });
    // }
    // else {
    yield put(
      declinedModifiedTicketsActions.saveTickets({
        count,
        data: result,
        profiles: profiles,
        accounts: accounts,
        profileAds: profileAds,
      }),
    );
    // };
  } catch (err) {
    console.log(err);

    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export default function* onlineTicketsSaga() {
  yield all([takeEvery(Types.GET_DECLINED_MODIFIED_TICKETS, getDeclinedModifiedList)]);
}
