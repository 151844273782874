import { HttpRequest } from 'services';

const apiUrl = '/messenger';

export const messagesService = {
	getList: params => {
		return HttpRequest.get(apiUrl + '/reports?', {
			params,
		});
	},

	getReportChat: params => {
		return HttpRequest.get(apiUrl + '/message/chunk?', {
			params,
		});
	},

	getReportChatByThreadId: ({ id, params }) => {
		return HttpRequest.get(apiUrl + `/message/${id}?`, {
			params,
		});
	},

	getThread: id => {
		return HttpRequest.get(apiUrl + `/threads/${id}`);
	},

	reportsViewed: id => {
		return HttpRequest.patch(apiUrl + `/reports/${id}`);
	},
};
