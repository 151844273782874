import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import { Types } from './types/index';
import { accountDetailsService, mediaService /* onlineTickets */ } from 'services';
import { accountDetailsActions } from 'store/slices/accountDetails';
import { throwErrorActions } from 'store/slices/throwError';
import configs from 'config';

import { accountProfileDetailsSelector } from 'store/reselect';

export function* getAccountDetails(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.getAccountDetails(props.id);
      })(),
    );

    // const result = accountDetailsService.getAccountDetails({ id: props.id });
    yield put(accountDetailsActions.accountData({ data: data }));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* getVerifProfilInfo(props) {
  try {
    let verifImages = null;
    let result = {
      profile: null,
      ads: null,
    };

    const reqValues = yield call(() =>
      (async () => {
        return await Promise.allSettled([
          accountDetailsService.getProfileById(props.id),
          accountDetailsService.getAdVerifPhotos(props.id),
          accountDetailsService.getProfileAds(''),
        ]);
      })(),
    );

    if (reqValues[0].status === 'fulfilled') {
      result.profile = reqValues[0].value.data;
    }
    if (reqValues[2].status === 'fulfilled') {
      result.ads = reqValues[2].value.data;
    }
    if (reqValues[1].value?.data) {
      yield (async () => {
        const images = await mediaService.getBulkImages({
          ids: reqValues[1].value.data.map(el => el.image_id),
        });
        let res = {};
        reqValues[1].value.data.forEach((el, i) => {
          if (images.data[i]) {
            res[el._id] = { ...images.data[i], ...el };
          }
        });
        verifImages = res;
      })();

      if (verifImages) {
        yield put(
          accountDetailsActions.saveProfileInfo({
            ...result,
            verifImages: verifImages,
          }),
        );
      }
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* updateProfileAd(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.updateProfileAd(props.id, props.update);
      })(),
    );

    if (data.result) {
      yield put(accountDetailsActions.updateProfileAd(props.update));
      yield put(throwErrorActions.updateSuccess('Advertisement `successfully updated`'));
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));

    props.setValue(prevState => ({
      ...prevState,
      load: false,
    }));
  }
}

export function* getProfileById(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.getProfileById(props.id);
      })(),
    );

    yield put(accountDetailsActions.saveProfileInfo(data));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* getProfileAdPhotos(props) {
  try {
    // const result = {
    // 	imagesInfo: null,
    // 	imagesPaths: null,
    // };
    //
    // const { data } = yield call(() => (async () => {
    // 	return await accountDetailsService.getProfileAdPhotos(props.id);
    // })());
    //
    // result.imagesInfo = data;
    //
    // if (data && data.length) {
    // 	const images = yield call(() => (async () => {
    // 		return await mediaService.getBulkImages({
    // 			ids: data.map(el => el.image_id),
    // 		});
    // 	})());
    //
    // 	result.imagesPaths = images.data;
    // }
    //
    // yield put(accountDetailsActions.saveAdImages({ [props.id]: result }));

    const result = {
      approved: [],
      declined: [],
      pending: [],
    };

    const { data } = yield call(() => accountDetailsService.getProfileAdPhotos(props.id));

    if (data?.length) {
      const images = yield call(() =>
        mediaService.getBulkImages({
          ids: data.map(el => el.image_id),
        }),
      );

      if (images.data) {
        data.forEach((el, i) => {
          const key = el.status === 'autoapproved' ? 'pending' : el.status;

          result[key].push({
            ...images.data[i],
            ...el,
          });
        });
      }
    }

    yield put(accountDetailsActions.saveAdImages({ [props.id]: result }));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

// TODO:::: need to refactor
export function* getAdDetails(props) {
  try {
    const result = {
      ad: null,
      account: null,
      expenses: null,
      credit: null,
      adsPhotos: {},
    };

    const reqValues = yield call(
      async () =>
        await Promise.allSettled([
          accountDetailsService.getProfileAd(props.id),
          accountDetailsService.getAccountDetails(props.accountId),
          // accountDetailsService.getCredit(props.accountId),
          accountDetailsService.getProfileAdPhotos(props.id),
        ]),
    );

    if (reqValues[0].status === 'fulfilled') {
      result.ad = reqValues[0].value.data;
    }
    if (reqValues[1].status === 'fulfilled') {
      result.account = reqValues[1].value.data;
    }

    // if (reqValues[2].status === 'fulfilled') {
    //   result.credit = props.categoryData?.data;
    // }
    if (reqValues[2].status === 'fulfilled') {
      result.adsPhotos[props.id] = reqValues[2].value.data;
    }

    yield put(accountDetailsActions.saveAd(result));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* getProfiles(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.getProfiles(props.id);
      })(),
    );

    const profileDetails = yield select(accountProfileDetailsSelector);
    const isCompanyProfile = profileDetails?.role === 'company';

    const profiles = [...data];

    if (isCompanyProfile) {
      const { data: companyProfile } = yield call(() =>
        accountDetailsService.getCompanyProfileByAccountId(props.id),
      );

      companyProfile && profiles.unshift(companyProfile);
    }

    yield put(
      accountDetailsActions.saveProfile({
        data: profiles,
        accountId: props.id,
      }),
    );
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* sendContactVerification(props) {
  try {
    const isEmileVerification = props.contactVerificationsData.name === 'emails';

    const {
      data: { result },
    } = yield call(async () => {
      const data = isEmileVerification
        ? {
            email: props.contactVerificationsData.value,
            account_id: props.accountId,
          }
        : {
            account_id: props.accountId,
            country_code: props.contactVerificationsData.value.code,
            number: props.contactVerificationsData.value.number,
            purpose: 'profile',
          };

      return await accountDetailsService.sendContactVerification(
        data,
        isEmileVerification ? 'reset-email' : 'reset-phone-number',
      );
    });

    if (result) {
      yield put(
        throwErrorActions.updateSuccess(
          `${isEmileVerification ? 'Email' : 'Phone number'} has been successfully reset!`,
        ),
      );
    } else {
      yield put(throwErrorActions.updateMessage({ message: 'Something went wrong!!!' }));
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* checkVerificationsContact(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return props.contactVerificationsData.title === 'Email'
          ? await accountDetailsService.checkContactVerification(
              {
                email: props.contactVerificationsData.value,
                account_id: 2,
              },
              '/check-email',
            )
          : await accountDetailsService.checkContactVerification(
              {
                account_id: 2,
                country_code: props.contactVerificationsData.value.code,
                number: props.contactVerificationsData.value.number,
                purpose: 'profile',
                re_verify: true,
              },
              '/check-phone-number',
            );
      })(),
    );

    yield put(
      accountDetailsActions.checkContactVerification({
        data: data,
        name: props.contactVerificationsData.name,
        value: props.contactVerificationsData.value,
        index: props.contactVerificationsData.index,
      }),
    );
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* resetData() {
  try {
    yield put(accountDetailsActions.resetData());
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* resetPass(props) {
  try {
    yield call(() =>
      (async () => {
        return await accountDetailsService.resetPass({
          id: props.id,
          params: props.params,
        });
      })(),
    );
    props.closeModal();
  } catch (err) {
    setTimeout(() => {
      props.closeLoad(false);
    }, 800);
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* resetMemo(props) {
  try {
    yield call(() =>
      (async () => {
        await accountDetailsService.resetMemo({
          id: props.id,
          params: props.params,
        });
        setTimeout(() => {
          props.setMemo({ value: props.params.memo, load: false });
        }, 1000);
      })(),
    );
    yield put(throwErrorActions.updateSuccess(`memo has been successfully updated!`));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
    setTimeout(() => {
      props.setMemo({ value: props.params.memo, load: false });
    }, 1000);
  }
}

export function* getAndFilterLogs(props) {
  try {
    if (props.getConfigs) {
      const configs = yield call(() =>
        (async () => {
          return await accountDetailsService.getConfigs();
        })(),
      );
      if (configs.data) {
        yield put(
          accountDetailsActions.saveLogConfigs({
            max: configs.data.max ? configs.data.max : 1000,
            types: configs.data.types ? configs.data.types : [],
            actions: configs.data.actions ? configs.data.actions : [],
          }),
        );
      }
    }
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.getLogs({
          params: {
            ...props.params,
          },
          ...props.query,
        });
      })(),
    );

    if (data && props.cb) {
      props.cb();
    }
    yield put(accountDetailsActions.saveLogs(data));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
    if (props.cb) {
      props.cb();
    }
  }
}

export function* createLog(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.createLog({
          account_id: props.account_id,
          params: props.params,
        });
      })(),
    );

    if (data.result) {
      props.cd();
    }
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
    props.cd();
  }
}

export function* idOrPersonVerifi(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.idOrPersonVerification({
          data: props.data,
        });
      })(),
    );

    if (data) {
      props.cd();
      yield put(throwErrorActions.updateSuccess('Me'));
    }
  } catch (err) {
    props.cd();
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* getVerificationImages(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.getVerifiedImages(props.id);
      })(),
    );

    if (data.result) {
      const imagesPath = data.result.documents.length
        ? yield call(() =>
            (async () => {
              return await mediaService.getBulkImages({
                ids: data.result.documents.map(el => el.id),
              });
            })(),
          )
        : null;

      yield put(
        accountDetailsActions.saveProfileImages({
          id: props.id,
          data: {
            ...data.result,
            imagesPath: imagesPath
              ? imagesPath.data.map((img, index) => {
                  const newPath = img.path.split('.');
                  return {
                    ...img,
                    path: `${configs.IMAGE_BASE_URL + newPath[0]}_thumb_100.jpg`,
                    is_pinned: data.result.documents[index].is_pinned,
                  };
                })
              : null,
          },
        }),
      );
    }
  } catch (err) {
    // props.cd();
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* approveOrDecline(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.approveOrDecline({
          id: props.personId,
          body: props.body,
        });
      })(),
    );

    if (data) {
      yield put(
        accountDetailsActions.changeStatus({
          id: props.id,
          action: props.body.verification_action,
          personId: props.personId,
        }),
      );
    }

    yield put(throwErrorActions.updateSuccess('Success'));
    props.setLoad(false);
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
    props.setLoad(false);
  }
}

export function* pinPhoto(props) {
  try {
    yield call(async () => await accountDetailsService.pinPhoto(props.body));

    // if (data) {
    //   yield put(accountDetailsActions.changeStatus({
    //     id: props.id,
    //     action: props.body.verification_action,
    //     personId: props.personId
    //   }));
    // };

    yield put(throwErrorActions.updateSuccess('Success'));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* deleteVerificationPhoto(props) {
  try {
    const { data } = yield call(() =>
      (async () => {
        return await accountDetailsService.deletePhoto({
          imgId: props.imgId,
          id: props.id,
        });
      })(),
    );

    if (data.result) {
      yield put(
        accountDetailsActions.deleteProfileVerificationDoc({
          id: props.id,
          index: props.imgId.index,
        }),
      );
    }
    yield put(throwErrorActions.updateSuccess('Success'));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export default function* getAccountDetailsSaga() {
  yield all([
    takeEvery(Types.GET_ACCOUNT_DETAILS, getAccountDetails),
    takeEvery(Types.UPDATE_PROFILE_AD, updateProfileAd),
    takeEvery(Types.GET_PROFILE_ADD_DETAILS, getAdDetails),
    takeEvery(Types.GET_PROFILE_BY_ID, getProfileById),
    takeEvery(Types.RESET_DATA, resetData),
    takeEvery(Types.RESET_PASSWORD, resetPass),
    takeEvery(Types.RESET_MEMO, resetMemo),
    takeEvery(Types.CHECK_VERIFICATION_CONTACT, checkVerificationsContact),
    takeEvery(Types.SEND_VERIFICATION_CONTACT, sendContactVerification),
    takeEvery(Types.GET_ACTIVITY_LOGS, getAndFilterLogs),
    takeEvery(Types.CREATE_ACTIVITY_LOGS, createLog),
    takeEvery(Types.GET_PROFILES, getProfiles),
    takeEvery(Types.VERIFICATION_PERSON_OR_ID, idOrPersonVerifi),
    takeEvery(Types.SAVE_PROFILE_IMAGES, getVerificationImages),
    takeEvery(Types.APPROVE_OR_DECLINE, approveOrDecline),
    takeEvery(Types.GET_PROFILE_AD_PHOTOS, getProfileAdPhotos),
    takeEvery(Types.PIN_PHOTO, pinPhoto),
    takeEvery(Types.DELETE_PROFILE_VERIFICATION_PHOTO, deleteVerificationPhoto),
    takeEvery(Types.GET_PROFILE_VER_AD_INFO, getVerifProfilInfo),
  ]);
}
