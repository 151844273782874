import { call, put, takeEvery, all } from 'redux-saga/effects';
import { watchListService } from 'services';
import { Types } from './types/index';
import { watchListActions } from 'store/slices/watchList';
import { throwErrorActions } from 'store/slices/throwError';

export function* getWatchListList(props) {
  try {
    const { data } = yield call(() => watchListService.getList({ ...props.query }));

    yield put(watchListActions.saveListData(data));
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* deleteWatchList(props) {
  try {
    yield call(() => watchListService.deleteWatchList(props.id));

    yield put({ type: Types.GET_WATCH_LIST, query: { offset: 0, limit: 10, term: props.term } });

    // props.getNewList();
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export function* editWatchList(props) {
  try {
    yield call(() =>
      watchListService.editWatchList({
        id: props.data._id,
        params: props.state,
      }),
    );

    props.closeModal(false);
    yield put(
      watchListActions.saveEditList({
        data: {
          ...props.data,
          expiration_date: props.state.expiration_date,
          comment: props.state.comment,
        },
        index: props.index,
      }),
    );

    // props.getNewList();
  } catch (err) {
    yield put(throwErrorActions.updateMessage({ message: err.message }));
  }
}

export default function* watchList() {
  yield all([
    takeEvery(Types.GET_WATCH_LIST, getWatchListList),
    takeEvery(Types.DELETE_WATCH_LIST, deleteWatchList),
    takeEvery(Types.EDIT_WATCH_LIST, editWatchList),
  ]);
}
