import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Types } from 'store/sagas/types';
import TextField from '@material-ui/core/TextField';
import { LoadingIndicator } from 'app/components/Shared/LoadingIndicator';
import { Div } from 'styles/global-styles';
import { emailValidation } from 'utils/validations';
import { ReactComponent as Logo } from 'images/login-logo.svg';

import { Main, InputBlocks, RedFont, LogoBlock, SigButton, RowButton } from '../styles';

const Login = () => {
  const [state, setState] = useState({
    emailError: false,
    passwordError: false,
    email: '',
    password: '',
  });
  const [loadLogin, setLoadLogin] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = ({ target }) => {
    let cloneState = { ...state, [target.name]: target.value };

    if (target.name === 'email') {
      cloneState.emailError = !emailValidation.test(target.value);
    } else {
      cloneState.passwordError = !cloneState.password;
    }
    setState(cloneState);
  };

  const paintInput = (name, placeholder, required) => {
    return (
      <>
        {' '}
        <Div>
          <TextField
            fullWidth
            autoComplete="off"
            variant="outlined"
            size="small"
            name={name}
            value={state[name]}
            type={name === 'email' ? 'text' : 'password'}
            placeholder={placeholder}
            onKeyPress={e => {
              name === 'password' && e.key === 'Enter' && singIn();
            }}
            onChange={handleChange}
            InputProps={{
              style: {
                padding: '5px',
                borderRadius: '10px',
                background: 'background: rgba(255, 255, 255, 0.1)',
              },
            }}
          />
        </Div>
        <RedFont>
          {required && (name === 'email' ? 'Enter a valid email' : 'Password cannot be empty')}
        </RedFont>
      </>
    );
  };

  const singIn = () => {
    if (loadLogin) {
      return;
    }
    if (!state.emailError && !state.passwordError && state.email && state.password) {
      setLoadLogin(true);

      dispatch({
        type: Types.LOGIN,
        params: { email: state.email, password: state.password },
        history: history,
        setLoadLogin: setLoadLogin,
      });
    }
  };

  return (
    <Main>
      <InputBlocks>
        <LogoBlock>
          <Logo />
        </LogoBlock>
        {paintInput('email', 'Username', state.emailError)}
        {paintInput('password', 'Password', state.passwordError)}

        {loadLogin ? (
          <LoadingIndicator height="auto" />
        ) : (
          <RowButton>
            <SigButton onClick={singIn}>Sign in</SigButton>
          </RowButton>
        )}
      </InputBlocks>
    </Main>
  );
};

export default Login;
