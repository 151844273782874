import { HttpRequest } from 'services';

export const homepage = {
  getTicketsCount: status => {
    return HttpRequest.get(`tickets/count?status=${status}`);
  },

  getSuspectedTicketsCount: () => {
    return HttpRequest.get('fraud/accounts/match/count');
  },

  getMessageTicketsCount: () => {
    return HttpRequest.get('messenger/reports/unread');
  },

  getVerificationTicketsCount: () => {
    return HttpRequest.get('profile-verification/counts');
  },

  getWatchListTicketsCount: () => {
    return HttpRequest.get('account/watchlists/counts');
  },

  getSearchData: (body, offset = 0, limit = 100) => {
    return HttpRequest.post(
      `aggregator/accounts/cellar/search-term?offset=${offset}&limit=${limit}`,
      body,
    );
  },
};
