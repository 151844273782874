import styled from 'styled-components';
import { StyleConstants } from 'styles/StyleConstants';
import { mediaMax } from 'styles/media';

const HeaderStyle = styled.header`
  background-color: #fff;
  padding: ${props => (props.isMobileView ? '20px 30px 20px 100px' : '20px 30px')};
  height: ${StyleConstants.HEADER_HEIGHT};
  display: grid;
  grid: 1fr / 200px 1fr;
  align-items: center;
  box-shadow: 0px -1px 10px 0px rgb(0 0 0 / 10%);
  z-index: 222;
  ${mediaMax.sm`
  	grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  `}
`;

export { HeaderStyle };
