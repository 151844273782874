import { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

import options from 'config';

const TranslatorContext = createContext();

const TranslatorProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const changeLanguage = useCallback(async lang => {
    const data = await fetch(`${options.API_BASE_URL}/dictionary?locale=${lang}`);
    const translations = await data.json();

    setTranslations({ [lang]: translations || {} });
    setCurrentLanguage(lang);
  }, []);

  useEffect(() => {
    changeLanguage(currentLanguage);
  }, [changeLanguage, currentLanguage]);

  const translate = useCallback(
    (str, ...args) => {
      const currentTranslations = translations[currentLanguage] || Object.values(translations)[0];
      const translation = currentTranslations?.[str] || str;

      return !args.length ? translation : translation.replace(/__param__/g, () => args.shift());
    },
    [translations, currentLanguage],
  );

  const value = useMemo(() => ({ translate }), [translate]);

  return <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>;
};

const useTranslator = () => {
  const { translate: t, currentLanguage } = useContext(TranslatorContext) || {};

  return { t, currentLanguage };
};

export { useTranslator, TranslatorProvider };
