import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState= {
  tickets: null,
  filteredTickets: null,
  count: 0,
  profiles: null,
  accounts: null,
  profileAds: null,
  offlineTicketCount: 0,
};

export const offlineModifiedTicketsSlice = createSlice({
  name: 'offlineModifiedTickets',
  initialState,
  reducers: {
    saveOfflineTickets: (state, action) => {
      state.tickets = action.payload.data;
    },
    saveFilteredTickets: (state, action) => {
      state.filteredTickets = action.payload;
    },
    setOfflineTicketCount: (state, action) => {
      state.offlineTicketCount = action.payload;
    },
  },
});

export const { actions: offlineTicketsActions, reducer } = offlineModifiedTicketsSlice;

export const useOfflineModifiedTicketsSlice = () => {
  useInjectReducer({ key: offlineModifiedTicketsSlice.name, reducer });
  return { actions: offlineTicketsActions };
};
