export const StyleConstants = {
  NAV_BAR_HEIGHT: '4rem',
  BODY_BG: '#F1F1F7',
  HEADER_HEIGHT: '70px',
  BLUE: '#003D91',
  BLUE_50: '#0CAAE1',

  /*UI*/
  ACTIVE_STATE: '#0078D4',
  TEXT_COLOR: '#51545D',
  TEXT_COLOR_30: '#aeafb3',
  DISABLE_ICON_COLOR: '#C4C5D7',
  TEXT_SIZE: '14px',
  BASE_BORDER_COLOR: '#D8D8D8',
  SUCCESS_COLOR: '#7AB317',
  DANGER_COLOR: '#ff5267',
  ACTION_ICON_WRAPPER_SIZE: '32px',
  /*____________*/

  /*Aside Dashboard*/
  ASIDE_HEADER_BG: '#282A3D',
  ASIDE_HEADER_HEIGHT: '70px',
  ASIDE_BODY_BG: '#2C2E3F',
  ASIDE_WIDTH_OPEN: '250px',
  ASIDE_WIDTH_CLOSED: '80px',
  ASIDE_MENU_ICON_COLOR: '#646786',
  ASIDE_MENU_TEXT_COLOR: '#8689A9',
  ASIDE_MENU_LINK_ACTIVE_BG: '#282a3c',
  ASIDE_MENU_LINK_ACTIVE_COLOR: '#c2c3c7',
  ASIDE_MENU_TEXT_TITLE_COLOR: '#646786',
  /*______________*/

  /*Badge*/
  BADGE_BG_GREY: '#C4C5D7',
  /*________*/

  /*Tooltip*/
  TOOLTIP_TEXT_COLOR: '#fff',
  TOOLTIP_BG: '#000',
  TOOLTIP_OFFSET: '30px',
  /*_____________*/

  /*Tickets*/
  GRID_TICKET_TITLE_OFF_BG: '#5763E0',
  GRID_TICKET_TITLE_ON_BG: '#2DA1FA',
  GRID_TICKET_TITLE_MSC_BG: '#29C0A3',
  GRID_TICKET_TITLE_FP_BG: '#616161',
  /*_______*/

  /*Fraud prevention*/
  FRAUD_PREVENTION_MULTIPLE_BG: '#ffb822',
  FRAUD_PREVENTION_SINGLE_BG: '#5763e0',
  FRAUD_PREVENTION_CREATION_BG: '#9816f4',
  /*_______*/

  /*Material Theme constants*/
  INPUT_TEXT_SIZE: '12px',
  INPUT_RADIUS: '4px',
  INPUT_BG: '#fff',
  INPUT_COLOR: '#717171',
  INPUT_BORDER_COLOR: '#E1E1E1',
  INPUT_DISABLED_BG: '#F7F7F7',
  INPUT_DISABLED_COLOR: 'rgba(0, 0, 0, 0.6)',
  BUTTON_TEXT_SIZE: '13px',
  /*_____________*/

  /*Table*/
  CELL_HEAD_COLOR: '#828282',
  CELL_HEAD_TXT_SIZE: '12px',
  CELL_BODY_TXT_SIZE: '14px',
  CELL_BODY_COLOR: '#4F4F4F',
  /*_________*/
};
