import { HttpRequest } from 'services';

const apiUrl = '/dictionary';

export const dictionaryService = {
  getList: (params) => {
    return HttpRequest.post(apiUrl + '/bulk', { params });
  },

  createDictionary: (data) => {
    return HttpRequest.post(apiUrl, data);
  },

  syncDictionary: () => {
    return HttpRequest.post(`${apiUrl}/sync`, {});
  },

  editDictionary: (data, id) => {
    return HttpRequest.post(apiUrl + '/' + id, data);
  },

  deleteItem: (id) => {
    return HttpRequest.delete(apiUrl + '/' + id);
  },

  sendImportedFile: (id, file) => {
    return HttpRequest.post(apiUrl + `/import?account_id=${id}`, file);
  },
};
