import styled from 'styled-components';

export const BadgeWrapper = styled.div`
	display: inline-flex;
	background-color: ${props => props.bg};
	color: #fff;
	font-size: 16px;
	padding: 1px 10px;
	font-weight: 400;
	border-radius: 3px;
`;
