import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState = {
	lists: [],
	count: 0,
};

export const watchListSlice = createSlice({
	name: 'watchList',
	initialState,
	reducers: {
		saveListData: (
			state,
			action,
		) => {
			state.lists = action.payload.data;
			state.count = action.payload.count;
		},
		saveEditList: (
			state,
			action,
		) => {
			const newList= [...state.lists];
			newList[action.payload.index] = action.payload.data;
			state.lists = newList;
		},
	},
});

export const { actions: watchListActions, reducer } = watchListSlice;

export const useWatchListSlice = () => {
	useInjectReducer({ key: watchListSlice.name, reducer });
	return { actions: watchListActions };
};
