import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';

import { IconInputWrapper } from 'styles/global-styles';

import { HeaderStyle } from './styles';

const Header = ({ isMobileView }) => {
  const history = useHistory();
  const [value, setValue] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    history.push({
      pathname: '/search',
      search: `?search=${value}`,
    });
  };

  return (
    <HeaderStyle isMobileView={isMobileView}>
      <IconInputWrapper direction="left">
        <AiOutlineSearch className="input--icon" color="#BBC0CE" size={16} />
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={value}
            onChange={e => setValue(e.target.value)}
            InputProps={{
              style: {
                paddingLeft: '20px',
                borderRadius: '20px',
              },
            }}
          />
        </form>
      </IconInputWrapper>
    </HeaderStyle>
  );
};

export default memo(Header);
