import styled from 'styled-components';
import { StyleConstants } from 'styles/StyleConstants';

export const AsideWrapper = styled.aside`
  width: ${props =>
    !props.isOpen
      ? StyleConstants.ASIDE_WIDTH_CLOSED
      : props.mobileView
      ? '100vw'
      : StyleConstants.ASIDE_WIDTH_OPEN};
  transition: 0.2s;
  z-index: 999;
  position: relative;
  .aside-header {
    padding: 15px 20px;
  }
`;

export const AsideHeader = styled.div`
  width: ${props =>
    !props.isOpen
      ? StyleConstants.ASIDE_WIDTH_CLOSED
      : props.mobileView && StyleConstants.ASIDE_WIDTH_CLOSED};
  background-color: ${StyleConstants.ASIDE_HEADER_BG};
  display: flex;
  align-items: center;
  height: ${StyleConstants.ASIDE_HEADER_HEIGHT};
  overflow: hidden;
  position: relative;
  z-index: 22;
  .logo-area {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-logo {
    width: 105px;
    height: 22px;
    position: absolute;
    left: 70px;
  }
  .aside-close-icon {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
`;

export const AsideBody = styled.div`
  background-color: ${StyleConstants.ASIDE_BODY_BG};
  height: 100vh;
  width: 100%;
  ${props =>
    props.mobileView
      ? {
          position: 'absolute',
          left: props.isOpen ? '0px' : '-' + StyleConstants.ASIDE_WIDTH_CLOSED,
        }
      : {
          paddingTop: StyleConstants.ASIDE_HEADER_HEIGHT,
          marginTop: '-' + StyleConstants.ASIDE_HEADER_HEIGHT,
        }}
`;

export const MenuListUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    a, button {
      width: 100%;
      background-color: transparent;
      border: none;
      padding: 10px 30px;
      color: ${StyleConstants.ASIDE_MENU_TEXT_COLOR};
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      cursor: pointer;
      font-size: ${StyleConstants.TEXT_SIZE};
      justify-content: flex-start;
      text-decoration: none;
      &:hover {
        background-color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_BG};a
        color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_COLOR};
        .menu-list-icon {
          color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_COLOR};
        }
      }
    }
    .menu-list-icon {
      font-size: 22px;
      flex-shrink: 0;
      margin-right: ${props => (props.isOpen ? '10px' : '0')};
      color: ${StyleConstants.ASIDE_MENU_ICON_COLOR};
    }
    .selected-nav-link {
      background-color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_BG};
      color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_COLOR};
      .menu-list-icon {
        color: ${StyleConstants.ASIDE_MENU_LINK_ACTIVE_COLOR};
      }
    }
  }
  .menu-list-item--text {
    flex-shrink: 0;
    display: ${props => (props.isOpen ? 'block' : 'none')};
  }
  .as-title {
    padding: 20px 30px 0;
    display: ${props => (props.isOpen ? 'block' : 'none')};
    white-space: nowrap;
    color: ${StyleConstants.ASIDE_MENU_TEXT_TITLE_COLOR};
    text-transform: uppercase;
    font-size: 11px;
  }
  &:first-child {
    margin-top: 40px;
  }
`;
