import { css } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const mediaMin = Object.keys(BREAKPOINTS.keys).reduce((acc, label) => {
  acc[label] = (first, ...interpolations) => css`
    @media (min-width: ${BREAKPOINTS.keys[label] + 1}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {});

export const mediaMax = Object.keys(BREAKPOINTS.keys).reduce((acc, label) => {
  acc[label] = (first, ...interpolations) => css`
    @media (max-width: ${BREAKPOINTS.keys[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {});

export const mediaBetween = Object.keys(BREAKPOINTS.between).reduce((acc, label) => {
  acc[label] = (first, ...interpolations) => css`
    @media (min-width: ${BREAKPOINTS.between[label].min}px) and (max-width: ${BREAKPOINTS.between[
        label
      ].max}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {});

/*
 * @types/styled-component is not working properly as explained in the github issue referenced above.
 * We must overcome this with custom typings, however, this might not work in time as the styled-components update.
 * Be carefull and keep an eye on the issue and the possible improvements
 */
