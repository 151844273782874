// @ts-ignore
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState= {
  lists: [],
  filteredList: [],
  filterQuantity: 0,
  dataForReset: [],
};

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    saveDictionary: (state, action) => {
      if (action.payload.length === 1) {
        const changedDataIndex = state.dataForReset.findIndex(
          el => el._id === action.payload[0]._id,
        );
        state.dataForReset.splice(changedDataIndex, 1, action.payload[0]);

        const changedListsIndex = state.lists.findIndex(el => el._id === action.payload[0]._id);
        state.lists.splice(changedListsIndex, 1, action.payload[0]);
      } else {
        state.dataForReset = [...action.payload];
        state.lists = [...action.payload];
      }
    },
    filterDictionary: (
      state,
      action,
    ) => {
      state.lists = [...action.payload.data];
      if (action.payload.hasFilter) {
        state.filteredList = [...action.payload.data];
      }
    },
  },
});

export const { actions: dictionaryActions, reducer } = dictionarySlice;

export const useDictionarySlice = () => {
  useInjectReducer({ key: dictionarySlice.name, reducer });
  return { actions: dictionaryActions };
};
