import { memo, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Portal from 'HOC/Portal';
import { TooltipContent } from './styles';
import { StyleConstants } from 'styles/StyleConstants';

const Tooltip = ({
  className = '',
  bg = '#000',
  containerId,
  direction,
  children,
  content,
  disableTooltip = false,
  maxWidth,
}) => {
  const [active, setActive] = useState(false);
  const [wrapperRect, setWrapperRect] = useState(null);
  const [tooltipRect, setTooltipRect] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
  });
  const wrapperRef = useRef(null);
  const tooltipRef = useRef(null);

  const generatePosition = tooltipRect => {
    const pos = {
      left: 0,
      top: 0,
    };
    const offset = +StyleConstants.TOOLTIP_OFFSET.split('px')[0];
    const wrLeft = wrapperRect?.left || 0;
    const wrWidth = wrapperRect?.width || 0;
    const wrHeight = wrapperRect?.height || 0;
    const wrTop = wrapperRect?.top || 0;
    const tTipHeight = tooltipRect?.height || 0;
    const tTipWidth = tooltipRect?.width || 0;
    if (direction === 'right') {
      pos.left = wrLeft + wrWidth + offset / 3;
      pos.top = wrTop + wrHeight / 2 - tTipHeight / 2;
    } else if (direction === 'left') {
      pos.left = wrLeft - tTipWidth - offset / 3;
      pos.top = wrTop + wrHeight / 2 - tTipHeight / 2;
    } else if (direction === 'top') {
      pos.left = wrLeft + wrWidth / 2 - tTipWidth / 2;
      pos.top = wrTop - tTipHeight - offset / 3;
    } else if (direction === 'bottom') {
      pos.left = wrLeft + wrWidth / 2 - tTipWidth / 2;
      pos.top = wrTop + wrHeight + offset / 3;
    }
    return pos;
  };

  useEffect(() => {
    const rect = wrapperRef.current?.getBoundingClientRect();
    if (rect) {
      setWrapperRect(rect);
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (active) {
      const rect = tooltipRef.current?.getBoundingClientRect() || null;
      setTooltipPosition(generatePosition(rect));
      setTooltipRect(rect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      className={`${className} tooltip-wrapper`}
      ref={wrapperRef}
    >
      {children}
      {active && !disableTooltip && (
        <Portal id={containerId}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <TooltipContent
              bg={bg}
              className={`${direction || 'top'}`}
              position={tooltipPosition}
              ref={tooltipRef}
              maxWidth={(maxWidth && maxWidth + 'px') || 'auto'}
              opacity={tooltipRect ? 1 : 0}
            >
              {content}
            </TooltipContent>
          </motion.div>
        </Portal>
      )}
    </div>
  );
};
export default memo(Tooltip);
