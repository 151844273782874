import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/Shared/LoadingIndicator';

export const AccountDetails = lazyLoad(
	() => import('./index'),
	module => module.AccountDetails,
	{
		fallback: <LoadingIndicator />,
	},
);
