import { getApproveData } from 'utils/getApproveData';
import { createSelector } from '@reduxjs/toolkit';
import { initialState as initialBaseState } from '../slices/baseReducer';
import { initialState as initialBlackList } from '../slices/blackListReducer';
import { initialState as initialAccountDetails } from '../slices/accountDetails';
import { initialState as initialTrowError } from '../slices/throwError';
import { initialState as initialDictionary } from '../slices/dictionary';
import { initialState as initialMessages } from '../slices/messages';
import { initialState as initalWatchList } from '../slices/watchList';
import { initialState as initalCategorySettings } from '../slices/categorySettings';
import { initialState as initalOnlineTickets } from '../slices/onlinetickets';
import { initialState as initalTrustworthiness } from '../slices/trustworthiness';

const baseState = (state) => state.base || initialBaseState;
const baseSelector = createSelector([baseState], state => state);

export const trustworthinessState = (state) =>
  state.trustworthiness || initalTrustworthiness;
const trustworthinessSelector = createSelector([trustworthinessState], state => state);

const blackListState = (state) => state.blackList || initialBlackList;
const blackListSelector = createSelector([blackListState], state => state);

export const getBlacklistMatchState = store => blackListState(store).blacklistMatch;
export const getBlacklistMatchText = store => getBlacklistMatchState(store).text;
export const getBlacklistMatchContact = store => getBlacklistMatchState(store).contact;

const accountDetailsState = (state) => state.accountDetails || initialAccountDetails;
export const accountDetailsAd = (state) => accountDetailsState(state).ad;
export const accountDetailsAdMeta = (state) => accountDetailsAd(state)?._meta;
const accountProfileDetailsSelector = createSelector([accountDetailsState], state => {
  return state.account;
});

export const getProfileAdConfigs = store => baseState(store).profileAdConfigs;

export const accountDetailsPinnedImage = (state) =>
  accountDetailsState(state).pinedImage;

export const accountConfig = state => accountDetailsState(state).accountConfig;

const getContactInfo = createSelector([accountDetailsState], state => {
  return state.account ? state.account.contact : {};
});

const getAdPhotos = createSelector([accountDetailsState], state => {
  return state.adPhotos;
});

const adsAdvertismentsData = createSelector([accountDetailsState], state => {
  return {
    adImages: state.adImges,
    ad: state.ad,
    showName: state.ad ? getApproveData(state.adProfiles, 'showname') : '',
  };
});

const getImgPaths = createSelector([accountDetailsState], state => {
  return state.adImgesPaths;
});

const getGlobalStatus = createSelector([accountDetailsState], state => {
  return state.ad ? state.ad.global_status : '';
});

const accountDetailsSelector = createSelector([accountDetailsState], state => state || {});

const accountLogConfigs = createSelector([accountDetailsState], state => state.logConfigs);

const accountLogs = createSelector([accountDetailsState], state => {
  return {
    logs: state.logs,
    count: state.logsCount,
  };
});

const getProfiles = createSelector([accountDetailsState], state => state.profiles);

const getAdProfiles = createSelector([accountDetailsState], state => {
  return state.adProfiles ? state.adProfiles : null;
});

const getAdAllProfiles = createSelector([accountDetailsState], state => {
  return state.adProfiles;
});

const adsCategories = createSelector([accountDetailsState], state => {
  return {
    labels: state.labels,
    categories: state.categories,
  };
});

const getProfileConfigs = createSelector(
  [accountDetailsState],
  state => state.profileConfigs || {},
);
export const getVerificationConfigs = createSelector(
  [accountDetailsState],
  state => state.verificationConfigs || {},
);

export const getPhotoModerationReasons = store =>
  getProfileConfigs(store)?.photo_moderation_reasons;

const getVerificationImg = createSelector([accountDetailsState], state => state.profileImges);

const trowErrorState = (state) => state.throwError || initialTrowError;
const trowErrorSelector = createSelector([trowErrorState], state => state);

const dictionaryState = (state) => state.dictionary || initialDictionary;
const dictionarySelector = createSelector([dictionaryState], state => state);

const messagesState = (state) => state.messages || initialMessages;
const messagesSelector = createSelector([messagesState], state => {
  return {
    lists: state.lists,
    count: state.count,
  };
});

const repoersChatAndThread = createSelector([messagesState], state => {
  return {
    chatList: state.chatList,
    theards: state.theards,
    hasAfter: state.hasAfter,
    hasBefore: state.hasBefore,
  };
});

// const watchListState = (state) => state.watchList || initialDictionary;
const watchListState = (state) => state.watchList || initalWatchList;
const watchListSelector = createSelector([watchListState], state => state.lists);

const categorySettingsState = (state) =>
  state.categorySettings || initalCategorySettings;
const categorySettingsSelector = createSelector([categorySettingsState], state => state);

const onlineTicketsState = (state) => state.onlineTickets || initalOnlineTickets;
const onlineTicketsDefaultSelector = createSelector([onlineTicketsState], state => state);

const offlineTicketsState = (state) => state.offlineTickets;
const offlineTicketsDefaultSelector = createSelector([offlineTicketsState], state => state);

const offlineModifiedTicketsState = (state) => state.offlineModifiedTickets;
const getOfflineModifiedTickets = (state) =>
  offlineModifiedTicketsState(state)?.tickets || [];

const getOfflineModifiedTicketsCount = (state) =>
  offlineModifiedTicketsState(state)?.offlineTicketCount;

const onlineModifiedTicketsState = (state) => state.onlineModifiedTickets;
const getOnlineModifiedTickets = (state) =>
  onlineModifiedTicketsState(state)?.tickets || [];

const getOnlineModifiedTicketsCount = (state) =>
  onlineModifiedTicketsState(state)?.onlineTicketsCount;

const offlinePhotoTicketsState = (state) => state.offlinePhotoTickets;
const offlinePhotoTicketsDefaultSelector = createSelector(
  [offlinePhotoTicketsState],
  state => state,
);

const onlinePhotoTicketsState = (state) => state.onlinePhotoTickets;
const onlinePhotoTicketsDefaultSelector = createSelector([onlinePhotoTicketsState], state => state);

const declinedModifiedTicketsDefaultState = (state) => state.declinedModifiedTickets;
const declinedModifiedTicketsDefaultSelector = createSelector(
  [declinedModifiedTicketsDefaultState],
  state => state,
);

export {
  baseSelector,
  offlineTicketsDefaultSelector,
  getOfflineModifiedTickets,
  getOnlineModifiedTickets,
  getOnlineModifiedTicketsCount,
  offlinePhotoTicketsDefaultSelector,
  onlinePhotoTicketsDefaultSelector,
  declinedModifiedTicketsDefaultSelector,
  blackListSelector,
  trowErrorSelector,
  trustworthinessSelector,
  accountDetailsSelector,
  accountProfileDetailsSelector,
  adsAdvertismentsData,
  getProfileConfigs,
  getImgPaths,
  getOfflineModifiedTicketsCount,
  getAdProfiles,
  getContactInfo,
  adsCategories,
  accountLogConfigs,
  accountLogs,
  getProfiles,
  getAdAllProfiles,
  getVerificationImg,
  dictionarySelector,
  messagesSelector,
  repoersChatAndThread,
  watchListSelector,
  categorySettingsSelector,
  onlineTicketsDefaultSelector,
  getGlobalStatus,
  getAdPhotos,
};
