import styled from 'styled-components';
import { StyleConstants } from 'styles/StyleConstants';

export const Logo = styled.div`
  background-color: ${StyleConstants.BLUE};
  color: ${StyleConstants.BLUE_50};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  font-size: 32px;
  align-items: center;
  justify-content: center;
  font-family: serif;
  font-weight: 900;
  cursor: ${props => (props.cursorProp ? 'pointer' : 'default')};
`;
